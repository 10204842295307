import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk/hooks/useAuth";

export default function useForecastSheetUpload() {
  const { DATE, hotelId } = useParams();
  const { token, authFetch } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();
  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [selectedDateNew, setSelectedDateNew] = useState(defaultValue);
  const [uploadDateData, setUploadDateData] = useState([]);
  const [hasDateData, setHasDateData] = useState(false);
  const [hasTableData, setHasTableData] = useState(false);
  const [timeOption, setTimeOption] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [uploadActualDate, setUploadActualDate] = useState(new Date());
  const formdata = useRef(new FormData());
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [Error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filename, setFileName] = useState("");
  const [SnackBarState, setSnackBarState] = useState(false);
  // const [snackBarColor, setSnackBarColor] = useState(true);

  const handleTimeOption = useCallback((event) => {
    setTimeOption(event.target.value);
    console.log(event.target.value);
  }, []);

  const formatInputValue = () => {
    if (selectedDateNew) {
      const value =
        `${
          selectedDateNew.day < 10
            ? "0" + selectedDateNew.day
            : selectedDateNew.day
        }` +
        "/" +
        `${
          selectedDateNew.month < 10
            ? "0" + selectedDateNew.month
            : selectedDateNew.month
        }` +
        "/" +
        `${selectedDateNew.year}`;
      return value;
    } else {
      if (!isNaN(new Date(DATE).getTime())) {
        const [year, mon, day] = DATE.split("-");

        if (year && mon && day) {
          if (day && day.length === 1) {
            const validDate = "0" + day + "/" + mon + "/" + year;
            return validDate;
          } else {
            const validDate = day + "/" + mon + "/" + year;
            return validDate;
          }
        }
      }
    }
  };

  async function fileHandler() {
    setOpenSelectBar(false);
    let year = uploadActualDate.getFullYear();
    let month = uploadActualDate.getMonth() + 1;
    let dt = uploadActualDate.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    formdata.current.append("uploadedFor", year + "-" + month + "-" + dt);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/forecast-file-budget`,
    });

    const { data, response } = await post(formdata.current);
    // if (response.status === 400) {
    //   setSnackBarColor(false);
    // } else {
    //   setSnackBarColor(true);
    // }
    if (
      data &&
      (Object.keys(data).length === 1 || Object.keys(data).length === 0)
    ) {
      setnetworkMsg(data?.messageToUser || "Invalid File");
      setOpen(true);
      setError(false);
    } else {
      setnetworkMsg("Upload Successful");
      setOpen(true);

      setToggle(false);
      setError(false);
    }
    formdata.current = new FormData();
  }

  const fileGetter = (file) => {
    setFileName(file[0].name);

    handleToggle();
    formdata.current.append("file", file[0]);
    formdata.current.append("description", "XLSX File");
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const removeFile = () => {
    setFileName("");
    formdata.current.delete("file");
    formdata.current.delete("description");
    formdata.current.delete("uploadedFor");
    setToggle(!toggle);
  };

  const GetDataByDate = useCallback(async () => {
    let year = selectedDateNew.year;
    let month = selectedDateNew.month;
    let dt = selectedDateNew.day;
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    const yyyymmdd = year + "-" + month + "-" + dt;
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/budget-bookings/file/${yyyymmdd} `,
    });
    const { data } = await get();

    if (data.length === 0) {
      setHasDateData(false);
      setTimeOption(null);
      setHasTableData(false);
    } else {
      setUploadDateData(data);
      setHasDateData(true);
      setTimeOption(data[0].id);
    }
  }, [authFetch, selectedDateNew, hotelId]);

  const GetTableData = useCallback(async () => {
    let id = timeOption;
    if (id !== null) {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/budget-bookings/by-file/${id}`,
      });
      const { data } = await get();
      if (data.error) {
        setHasTableData(false);
      } else {
        setHasTableData(true);
        setTableData(data);
      }
    }
  }, [authFetch, hotelId, timeOption]);

  useEffect(() => {
    GetDataByDate();
  }, [selectedDateNew, GetDataByDate]);
  useEffect(() => {
    GetTableData();
  }, [timeOption]);

  const handleClose = useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }, []);

  return {
    maximumDate,
    defaultValue,
    selectedDateNew,
    setSelectedDateNew,
    formatInputValue,
    uploadDateData,
    hasDateData,
    timeOption,
    handleTimeOption,
    hasTableData,
    tableData,
    fileHandler,
    setUploadActualDate,
    uploadActualDate,
    fileGetter,
    setOpenSelectBar,
    removeFile,
    filename,
    handleToggle,
    toggle,
    setToggle,
    openSelectbar,
    open,
    networkMsg,
    handleClose,
    GetDataByDate,
    // snackBarColor,
  };
}
