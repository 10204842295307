import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField as TF,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/system";
import { PrimaryButton, useAuth } from "../../sdk";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete } from "@mui/material";

function getModalStyle() {
  const top = 10;
  const left = 32;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}, -${left})`,
  };
}
const StyledModal = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    width: 662,
    height: 662,
    maxHeight: "80vh",
    backgroundColor: "#fff",
    boxShadow: 5,
    padding: "16px 16px 16px 24px",
  },
  ".formControl": {
    width: "95%",
  },
}));
const Modal = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Forecast = styled(Typography)`
  font: normal normal 600 20px/20px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;
const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;
const Head = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const InputDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-bottom: 26px;
  padding-right: 20px;
  overflow: auto;
  -webkit-scrollbar {
    display: none;
  }
`;

const Input = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 8px;
  padding-top: 22px;
  width: 100%;
`;

const TextField = styled(TF)`
  &.MuiFormControl-root {
    outline: none;
    background: transparent;
    margin: 0px;
    border-bottom: 1px solid #ffffff;
    height: 56px;
    min-width: 574px;
  }
  label {
    font: italic normal 300 16px/16px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
  label.MuiFormLabel-root.Mui-focused {
    font: italic normal 300 20px/16px Roboto;
    letter-spacing: 0px;
    color: #000000;
    background: transparent;
  }
  input {
    font: normal normal 300 13px/16px Roboto;
    letter-spacing: 0px;
    color: black;
    min-width: 160px;
    outline: none;
    margin: 0px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
  }
`;

const Label = styled(InputLabel)`
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  display: flex;
  align-items: left;
  justify-content: space-around;
  width: 100%;
`;

const InputText = styled(TextField)`
  .MuiInputBase-input {
    padding: 0px;
    text-align: right;
  }
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 8px;
`;

const AddBtn = styled(PrimaryButton)`
  background: #130453 0% 0% no-repeat padding-box;
  // border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 20px;
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomColorCheckbox = styled(Checkbox)(() => ({
  color: "rgb(48,111,188)",
  "&$checked": {
    color: "rgb(48,111,188)",
  },
}));

const CustomColorRadio = styled(Radio)(() => ({
  color: "rgb(48,111,188)",
  "&$checked": {
    color: "rgb(48,111,188)",
  },
}));

const typesOfPermissions = [
  { name: "canCreateAlgorithm" },
  { name: "canCreateEvent" },
  { name: "canCreateUser" },
  { name: "canUploadData" },
];

const temp = [
  { id: "1", role: "Super Admin" },
  { id: "2", role: "Admin" },
  { id: "3", role: "Manager" },
];

const reName = /^[a-zA-Z ]*$/;
const rePass = /^[a-zA-Z0-9@$_-]*$/;
const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EMPTY_DETAILS = {
  name: "",
  email: "",
  username: "",
  password: "",
  con_password: "",
  invalidName: false,
  invalidEmail: false,
  invalidUsername: false,
  invalidPassword: false,
  invalidConPassword: false,
};

export default function UserModal({
  setOpenAddUser,
  isInUpdateMode,
  updateData,
  chains,
  hotels,
  setError,
  setLoading,
  getUser,
  selectedChain,
  allRoles,
  setNetworkMsg,
  setOpen,
}) {
  const UPDATE_DETAILS = {
    name: updateData?.name,
    email: updateData?.email,
    username: updateData?.username,
    invalidName: false,
    invalidEmail: false,
    invalidUsername: false,
  };
  function getRoleName(value) {
    const role = allRoles?.find((role) => role.id === value);
    return role?.name;
  }

  const { token, hotelSelect, authFetch, isAdmin } = useAuth();
  const [modalStyle] = useState(getModalStyle);
  const [user, setUser] = useState(EMPTY_DETAILS);
  const [updateUser, setUpdateUser] = useState(UPDATE_DETAILS);
  const [permissionList, setPermissionList] = useState([]);

  const organizationId = hotelSelect?.organizationId;
  // const [selectedHotel, setSelectedHotel] = useState(
  //   isInUpdateMode
  //     ? updateData?.hotels
  //       ? updateData?.hotels.split(', ')
  //       : []
  //     : []
  // );

  const [hotelsFromChain, setHotelsFromChain] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRole, setSelectedRole] = useState(
    isInUpdateMode ? updateData.roleID : ""
  );
  const [selectedPermissions, setSelectedPermissions] = useState(
    isInUpdateMode
      ? updateData?.permission?.map((item) => {
          return {
            id: item?.permissionId,
          };
        })
      : []
  );

  useEffect(() => {
    if (hotels) setSelectedHotel(updateData?.hotelList);
  }, [updateData, hotels]);

  const getAllPermissions = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/permission`,
      });
      const { data, error } = await get();
      if (data) {
        setPermissionList(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [authFetch]);

  useEffect(() => {
    getAllPermissions();
  }, [getAllPermissions]);

  let addedHotel = [];
  function dataCompilation() {
    hotels.map((hotel) => {
      const match = selectedHotel?.find((selected) => {
        return selected === hotel?.name;
      });
      if (match) {
        addedHotel = [
          ...addedHotel,
          { id: hotel?.id, organizationId: hotel?.organizationId },
        ];
      } else {
        return;
      }
    });

    const data = !isInUpdateMode
      ? {
          name: user.name?.trim(),
          email: user.email,
          username: user.username,
          password: user.password,
          confirmPassword: user.con_password,
          hotels: selectedHotel?.map(({ id, organizationId }) => ({
            id,
            organizationId,
          })),
          roleID: selectedRole,
          permission: selectedPermissions,
        }
      : {
          id: updateData.id,
          name:
            updateUser.name !== "" ? updateUser.name?.trim() : updateData.name,
          email: updateUser.email !== "" ? updateUser.email : updateData.email,
          username:
            updateUser.username !== ""
              ? updateUser.username
              : updateData.username,
          hotels: selectedHotel.map(({ id, organizationId }) => ({
            id,
            organizationId,
          })),
          roleID: selectedRole,
          permission: selectedPermissions,
        };
    return data;
  }

  const isAllValid = (data) => {
    return (
      data.name !== "" &&
      data.email !== "" &&
      data.username !== "" &&
      data.password !== "" &&
      data.confirmPassword !== "" &&
      data.roleID &&
      data.hotels
    );
  };

  async function handleUserSubmit() {
    setLoading(true);
    const dataCompiled = dataCompilation();
    if (!isAllValid(dataCompiled)) {
      setLoading(false);
      setNetworkMsg("All Fields Required");
      setOpen(true);
      return;
    }
    const id = isAdmin ? selectedChain : organizationId;
    if (token) {
      const { post } = await authFetch({
        path: `/organization/${organizationId}/user`,
      });
      const { data, error, response } = await post({
        ...dataCompiled,
        organizationId: parseInt(id),
      });
      if (
        data &&
        (data.Message === "Create User: Validation Failed" ||
          data.Message === "Create User: Email Unavailable")
      ) {
        if (data.Message === "Create User: Email Unavailable") {
          setNetworkMsg("Email Unavailable");
          setOpen(true);
        } else {
          setNetworkMsg("Fill Data Carefully");
          setOpen(true);
        }
      } else if (data && response.status === 400) {
        setNetworkMsg(data.message || " E-mail Validation Failed For User");
        setOpen(true);
      } else {
        setNetworkMsg("User Added Successfully");
        setOpen(true);
      }
    }
    setOpenAddUser(false);
    getUser();
    setLoading(false);
  }

  const [isOpen, setIsOpen] = useState(false);

  async function handleUpdateUserSubmit() {
    setLoading(true);
    const dataCompiled = dataCompilation();
    const id = isAdmin ? selectedChain : organizationId;

    if (token) {
      const { put } = await authFetch({
        path: `/organization/${organizationId}/user`,
      });
      const { data, error } = await put({
        ...dataCompiled,
        organizationId: parseInt(id),
      });
      if (data) {
        setNetworkMsg("User Updated Successfully");
        setOpen(true);
        setError(false);
      } else {
        setNetworkMsg("Something Went Wrong");
        setOpen(true);
      }
    }

    setOpenAddUser(false);
    getUser();
    setLoading(false);
  }

  const handleUserInfo = (key, value) => {
    let isValid = true;
    if (key === "name") {
      isValid = value.length > 0 && reName.test(String(value).trim());
    } else if (key === "username") {
      isValid = value.length > 0 && rePass.test(String(value).trim());
    } else if (key === "email") {
      isValid = value.length > 0 && reEmail.test(String(value).trim());
    } else if (key === "password") {
      isValid = value.length > 0 && rePass.test(String(value).trim());
    } else if (key === "con_password") {
      isValid = value.length > 0 && value === user.password;
    }

    if (key === "name") {
      isInUpdateMode === true
        ? setUpdateUser({ ...updateUser, [key]: value, invalidName: !isValid })
        : setUser({ ...user, [key]: value, invalidName: !isValid });
    }
    if (key === "username") {
      isInUpdateMode === true
        ? setUpdateUser({
            ...updateUser,
            [key]: value.trim(),
            invalidUsername: !isValid,
          })
        : setUser({ ...user, [key]: value.trim(), invalidUsername: !isValid });
    }
    if (key === "email") {
      isInUpdateMode === true
        ? setUpdateUser({
            ...updateUser,
            [key]: value.trim(),
            invalidEmail: !isValid,
          })
        : setUser({ ...user, [key]: value.trim(), invalidEmail: !isValid });
    }

    if (key === "password") {
      setUser({ ...user, [key]: value.trim(), invalidPassword: !isValid });
    }
    if (key === "con_password") {
      setUser({ ...user, [key]: value.trim(), invalidConPassword: !isValid });
    }
  };

  // function displayRoles(perm) {
  //   switch (perm) {
  //     case "salesTeam":
  //       return "Test Hotel User - Sales Team";
  //     case "redskyRevManager":
  //       return "RedSKY Revenue Manager";
  //     case "revManager":
  //       return "Hotel FOM / Revenue Manager";
  //     case "GM":
  //       return "Hotel General Manager";
  //     case "ownerVP":
  //       return "Hotel Owner / VP - Chain";
  //     case "revExec":
  //       return "RedSKY Revenue Executive";
  //     case "redskyAdmin":
  //       return "RedSKY Admin User";
  //     case "superAdmin":
  //       return "Precium Super Admin User";
  //     case "salesRepresentative":
  //       return "Hotel Sales Representative";
  //     default:
  //       return "";
  //   }
  // }

  const displayRoles = (selected) => {
    const val = allRoles?.filter((item) => item?.id === selected);
    return val?.[0]?.description;
  };
  useEffect(() => {
    if (selectedHotel?.length === hotels?.length) setIsAllSelected(true);
  }, [hotels?.length, selectedHotel?.length]);

  const handlePermissionChange = (event) => {
    const value = event.target.value;
    const selectedIds = typeof value === "string" ? value.split(",") : value;
    const newSelectedPermissions = selectedIds.map((id) => ({ id }));
    setSelectedPermissions(newSelectedPermissions);
  };

  const handleHotelChange = (event, newValue) => {
    if (newValue.includes("selectAll")) {
      if (isAllSelected) {
        setSelectedHotel([]);
        setIsAllSelected(false);
      } else {
        setSelectedHotel(hotels);
        setIsAllSelected(true);
      }
    } else {
      setSelectedHotel(newValue);
      setIsAllSelected(newValue.length === hotels.length);
    }
  };

  const handleChainChange = (event, value) => {
    setHotelsFromChain(value);
    const hotelsToSelect = hotels?.filter(
      (item) => item?.organizationId === value?.id
    );
    setSelectedHotel(hotelsToSelect);
    setIsAllSelected(false);
  };

  return (
    <StyledModal>
      <Modal style={modalStyle} className="paper">
        <Head>
          <Forecast> {isInUpdateMode ? "Update User" : "Add User"}</Forecast>
          <Cross onClick={() => setOpenAddUser(false)}>&times;</Cross>
        </Head>
        <InputDetails
          onScroll={() => {
            setIsOpen(false);
          }}
        >
          <Input>
            {isInUpdateMode === true ? (
              <TextField
                margin="dense"
                className={updateUser.invalidName ? "invalid" : ""}
                error={updateUser.invalidName}
                name="name"
                value={updateUser.name}
                onChange={(e) => handleUserInfo("name", e.target.value)}
                label="Name"
                helperText={updateUser.invalidName ? "Invalid Entry" : ""}
              ></TextField>
            ) : (
              <TextField
                margin="dense"
                className={user.invalidName ? "invalid" : ""}
                error={user.invalidName}
                name="name"
                value={user.name}
                onChange={(e) => handleUserInfo("name", e.target.value)}
                label="Name"
                helperText={user.invalidName ? "Invalid Entry" : ""}
              />
            )}
          </Input>
          <Input>
            {isInUpdateMode === true ? (
              <TextField
                margin="dense"
                className={updateUser.invalidUsername ? "invalid" : ""}
                error={updateUser.invalidUsername}
                name="username"
                value={updateUser.username}
                onChange={(e) => {
                  handleUserInfo("username", e.target.value);
                }}
                label="Username"
                helperText={updateUser.invalidUsername ? "Invalid Entry" : ""}
              ></TextField>
            ) : (
              <TextField
                margin="dense"
                className={user.invalidUsername ? "invalid" : ""}
                error={user.invalidUsername}
                name="username"
                value={user.username}
                onChange={(e) => {
                  handleUserInfo("username", e.target.value);
                }}
                label="Username"
                helperText={user.invalidUsername ? "Invalid Entry" : ""}
              />
            )}
          </Input>
          <Input>
            {isInUpdateMode === true ? (
              <TextField
                margin="dense"
                className={updateUser.invalidEmail ? "invalid" : ""}
                error={updateUser.invalidEmail}
                name="email"
                value={updateUser.email}
                onChange={(e) => handleUserInfo("email", e.target.value)}
                label="Email"
                helperText={updateUser.invalidEmail ? "Invalid Entry" : ""}
              />
            ) : (
              <TextField
                margin="dense"
                className={user.invalidEmail ? "invalid" : ""}
                error={user.invalidEmail}
                name="email"
                value={user.email}
                onChange={(e) => handleUserInfo("email", e.target.value)}
                label="Email"
                helperText={user.invalidEmail ? "Invalid Entry" : ""}
              />
            )}
          </Input>
          {isInUpdateMode === false && (
            <Input>
              <TextField
                margin="dense"
                type="password"
                className={user.invalidPassword ? "invalid" : ""}
                error={user.invalidPassword}
                name="password"
                value={user.password}
                onChange={(e) => handleUserInfo("password", e.target.value)}
                label="Password"
                helperText={user.invalidPassword ? "Invalid Entry" : ""}
              />
            </Input>
          )}
          {isInUpdateMode === false && (
            <Input>
              <TextField
                margin="dense"
                type="password"
                className={user.invalidConPassword ? "invalid" : ""}
                error={user.invalidConPassword}
                name="con_password"
                value={user.con_password}
                onChange={(e) => handleUserInfo("con_password", e.target.value)}
                label="Confirm Password"
                helperText={user.invalidConPassword ? "Invalid Entry" : ""}
              />
            </Input>
          )}

          <Input>
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
              }}
              className="formControl"
            >
              <InputLabel
                style={{ font: "italic normal 300 15px/18px Roboto" }}
              >
                Roles
              </InputLabel>
              <Select
                value={selectedRole}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Placeholder</em>;
                  }

                  return <em>{displayRoles(selected)}</em>;
                }}
                label="Roles"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  getContentAnchorEl: null,
                }}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
              >
                {allRoles?.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    <ListItemText primary={role?.description} />
                    <CustomColorRadio checked={selectedRole === role?.id} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Input>
          {getRoleName(selectedRole) === "freeRole" && (
            <Input>
              <FormControl
                variant="outlined"
                style={{
                  width: "100%",
                }}
                className="formControl"
              >
                <InputLabel
                  style={{ font: "italic normal 300 15px/18px Roboto" }}
                >
                  Permissions
                </InputLabel>
                <Select
                  multiple
                  value={selectedPermissions.map((item) => item.id)}
                  onChange={handlePermissionChange}
                  disableUnderline
                  label="Permissions"
                  classes={{
                    paper: "dropdownStyle",
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }
                    return selected
                      .map((id) => {
                        const permission = permissionList.find(
                          (p) => p.id === id
                        );
                        return permission ? permission?.description : "";
                      })
                      .join(", ");
                  }}
                  MenuProps={{ getContentAnchorEl: null }}
                >
                  {permissionList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText
                        classes={{
                          primary: "dropdownStyle",
                        }}
                        primary={item?.description}
                      />
                      <Checkbox
                        checked={selectedPermissions.some(
                          (perm) => perm.id === item.id
                        )}
                        style={{
                          color: "rgb(48,111,188)",
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Input>
          )}

          <Input>
            {chains && (
              <Autocomplete
                options={chains}
                getOptionLabel={(option) => option?.name}
                value={hotelsFromChain}
                onChange={handleChainChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Chain"
                    variant="outlined"
                    margin="dense"
                  />
                )}
              />
            )}
          </Input>

          <Input>
            {hotels && (
              <Autocomplete
                multiple
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                limitTags={6}
                options={["selectAll", ...hotels] ?? []} // Add "Select All" option
                value={selectedHotel ?? []}
                disableCloseOnSelect
                getOptionLabel={(option) =>
                  option === "selectAll" ? "Select All" : option?.name
                }
                onChange={handleHotelChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <CustomColorCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        option === "selectAll" ? isAllSelected : selected
                      }
                    />
                    {option === "selectAll" ? "Select All" : option?.name}
                  </li>
                )}
                renderTags={(selected, getTagProps) => {
                  const displayedTags = selected?.slice(0, 6);
                  const moreCount = selected?.length - 6;

                  return (
                    <>
                      {displayedTags.map((option, index) => (
                        <Chip
                          key={option?.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                        />
                      ))}
                      {moreCount > 0 && (
                        <Chip label={`+${moreCount} more hotels`} />
                      )}
                    </>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    maxRows={3}
                    margin="dense"
                    {...params}
                    label="Hotels"
                    // placeholder="Hotels"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Input>
        </InputDetails>
        {isInUpdateMode === false ? (
          <AddBtn onClick={() => handleUserSubmit()}>ADD</AddBtn>
        ) : (
          <AddBtn onClick={() => handleUpdateUserSubmit()}>UPDATE</AddBtn>
        )}
      </Modal>
    </StyledModal>
  );
}
