import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getISODate, moveMonth, useAuth } from "../../sdk";

const pickupColorPalette = ["#34A853", "#FBBC05", "#EA4335"];
const priceColorPalette1 = [
  "#5B2C6F",
  "#7FFF00",
  "#B68D40",
  "#FBBC05",
  "#FF00CC",
  "#037A90",
  "#FF6600",
  "#669900",
  "#455A64",
];
const priceColorPalette = [
  "#886F6F",
  "#EE33BB",
  "#D67D3E",
  "#B8405E",
  "#BF9270",
  "#694E4E",
  "#5B2C6F",
  "#7FFF00",
  "#455A64",
];

const MAX_VALUE = 50;
const MIN_VALUE = -50;

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const MONTHSARRAY = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export default function useDashboard(
  setLoading,
  setHaveData,
  setError,
  selectedDate,
  isComparitive,
  comparitiveDate,
  setIsComparitive,
  setNetworkMsg
) {
  const [comp1, setComp] = useState([]);
  const { token, authFetch } = useAuth();
  const { hotelId } = useParams();
  const [data, setData] = useState({});
  const [pickup, setPickup] = useState([]);
  const [price, setPrice] = useState([]);
  const [priceForFifteenDay, setPriceForFifteenDay] = useState([]);
  const [priceForSevenDay, setPriceForSevenDay] = useState([]);
  const [datesSevenDay, setDatesSevennDay] = useState([]);
  const [priceForFourteenDay, setPriceForFourteenDay] = useState([]);
  const [datesFourteenDay, setDatesFourteenDay] = useState([]);
  const [priceForTwentyOneDay, setPriceForTwentyOneDay] = useState([]);
  const [datesTwentyOneDay, setDatesTwentyOneDay] = useState([]);
  const [dates, setDates] = useState([]);
  const [datesFifteenDay, setDatesFifteenDay] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [comparitiveData, setComparitiveData] = useState({});
  const [occupancy, setOccupancy] = useState([]);
  const [idx, setIndex] = useState(0);
  const [idxNew, setIndexNew] = useState(0);
  const [graphdateArray, setGraphDateArray] = useState([]);
  const [graphpriceArray, setGraphPriceArray] = useState([]);
  const [priceGraph, setPriceGraph] = useState([]);
  const [datesGraph, setDatesGraph] = useState([]);
  const [arr, setArr] = useState([]);
  const [revpar, setRevpar] = useState([]);
  const [open, setOpen] = useState(false);
  const [occDataNew, setOccDataNew] = useState([]);
  const [occDataCurrent, setOccDataCurrent] = useState([]);
  const [arrDataNew, setArrDataNew] = useState([]);
  const [arrDataCurrent, setArrDataCurrent] = useState([]);
  const [revParDataNew, setRevParDataNew] = useState([]);
  const [revParDataCurrent, setRevParDataCurrent] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [hotelName, setHotelName] = useState("");
  const [opacity, setOpacity] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [opacitySevenDays, setOpacitySevenDays] = useState([]);
  const [dataKeysSevenDays, setDataKeysSevenDays] = useState([]);
  const [opacityThirtyDays, setOpacityThirtyDays] = useState([]);
  const [dataKeysThirtyDays, setDataKeysThirtyDays] = useState([]);
  const [comparitiveDateNew, setComparitiveDateNew] = useState("");
  const [selectedDateNew, setSelectedDate] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [sevenDayMax, setSevenDayMax] = useState(0);
  const [fourteenDayMax, setFourteenDayMax] = useState(0);

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear() - 1
  );
  const [yearOptions, setYearOptions] = useState([
    new Date().getFullYear() - 1,
  ]);
  const pickupOptions = React.useMemo(() => {
    let graphMaxLimit = MAX_VALUE;
    let graphMinLimit = MIN_VALUE;
    (data.pickup || []).forEach((item) => {
      graphMaxLimit = Math.max(
        item.yesterday,
        Math.max(item.threeDay, Math.max(item.sevenDay, graphMaxLimit))
      );
      graphMinLimit = Math.min(
        item.yesterday,
        Math.min(item.threeDay, Math.min(item.sevenDay, graphMaxLimit))
      );
    });
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8,
        },
      },
      tooltips: {
        bodyFontFamily: "Roboto",
        backgroundColor: "white",
        bodyFontColor: "#777777",
        bodyFontSize: 14,
        titleFontColor: "#777777",
      },

      plugins: {
        datalabels: {
          display: (context) => {
            const display =
              context.dataset.data[context.dataIndex] === 0 ? false : true;
            return display;
          },
          align: (context) => {
            const align =
              context.dataset.data[context.dataIndex] >= 0 ? "end" : "start";
            return align;
          },
          anchor: (context) => {
            const anchor =
              context.dataset.data[context.dataIndex] >= 0 ? "end" : "start";
            return anchor;
          },
          font: { size: "10", fontFamily: "Roboto" },
        },
      },
      legend: {
        position: "bottom",
        align: "left",
        labels: {
          fontFamily: "Roboto",
          fontSize: 14,
          boxWidth: 10,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontFamily: "Roboto",
              fontColor: "#777777",
              fontSize: 14,

              suggestedMin: 0,
              suggestedMax: 20,
            },
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "black",
            },
            scaleLabel: {
              display: true,
              labelString: "Pick-Up",
              fontColor: "#777777",
              fontFamily: "Roboto",
              fontSize: 14,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              color: "grey",
              zeroLineColor: "grey",
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "#777777",
              fontSize: 12,
            },
          },
        ],
      },
    };
  }, [data]);

  const priceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 8,
        right: 8,
        top: 0,
        bottom: 0,
      },
    },
    tooltips: {
      intersect: false,

      itemSort: function (a, b) {
        return b.datasetIndex - a.datasetIndex;
      },
      bodyFontFamily: "Roboto",

      backgroundColor: "white",
      bodyFontColor: "#777777",
      bodyFontSize: 14,

      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label.includes("%")) {
            return label + " : " + tooltipItem.yLabel + "%";
          }

          if (tooltipItem.yLabel === 0) {
            return label + ": Sold Out";
          } else {
            return label + ": " + tooltipItem.yLabel;
          }
        },
      },
    },
    hover: {
      intersect: false,
    },
    legend: {
      position: "bottom",
      align: "left",
      reverse: true,
      labels: {
        fontFamily: "Roboto",
        fontSize: 14,
        boxWidth: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        fill: false,
        tension: 0.1,
      },
    },
    scales: {
      xAxes: [
        {
          offset: true,
          labels: dates,
          display: true,

          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 13,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "right",
          id: "Price",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          labels: {
            show: true,
            labelString: "Price",
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
          },
          scaleLabel: {
            display: true,
            labelString: "Price",
            fontColor: "#777777",
            fontFamily: "Roboto",
            fontSize: 14,
          },
        },
        {
          type: "linear",
          display: true,
          position: "left",
          id: "Occupancy",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            beginAtZero: true,

            stepSize: 25,
            max: 100,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
            callback: function (value) {
              return value + "%";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Occupancy%",
            fontColor: "#777777",
            fontFamily: "Roboto",
            fontSize: 14,
          },
        },
      ],
    },
  };

  const priceOptionsForFifteenDay = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 8,
        right: 8,
        top: 0,
        bottom: 0,
      },
    },
    tooltips: {
      mode: "label",
      itemSort: function (a, b) {
        return b.datasetIndex - a.datasetIndex;
      },
      bodyFontFamily: "Roboto",

      backgroundColor: "white",
      bodyFontColor: "#777777",
      bodyFontSize: 14,
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label.includes("%")) {
            return label + " : " + tooltipItem.yLabel + "%";
          }

          if (tooltipItem.yLabel === 0) {
            return label + ": Sold Out";
          } else {
            return label + " : " + tooltipItem.yLabel;
          }
        },
      },
    },
    legend: {
      position: "bottom",
      align: "left",
      reverse: true,
      labels: {
        fontFamily: "Roboto",
        fontSize: 14,
        boxWidth: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        fill: false,
        tension: 0.4,
      },
    },
    scales: {
      xAxes: [
        {
          offset: true,
          labels: datesFifteenDay,
          display: true,
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 13,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "right",
          id: "Price",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          labels: {
            show: true,
            labelString: "Price",
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
          },
          scaleLabel: {
            display: true,
            labelString: "Price",
            fontColor: "#777777",
            fontFamily: "Roboto",
          },
        },
        {
          type: "linear",
          display: true,
          position: "left",
          id: "Occupancy",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            beginAtZero: true,

            stepSize: 25,
            max: 100,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 10,
            callback: function (value) {
              return value + "%";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Occupancy%",
            fontColor: "#777777",
            fontFamily: "Roboto",
          },
        },
      ],
    },
  };

  const priceOptionsForSevenDay = {
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: {
        left: 8,
        right: 8,
        top: 0,
        bottom: 0,
      },
    },
    tooltips: {
      intersect: false,

      itemSort: function (a, b) {
        return b.datasetIndex - a.datasetIndex;
      },
      bodyFontFamily: "Roboto",
      backgroundColor: "white",
      bodyFontColor: "#777777",
      bodyFontSize: 14,

      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label.includes("%")) {
            return label + " : " + tooltipItem.yLabel + "%";
          }

          if (tooltipItem.yLabel === 0) {
            return label + ": Sold Out";
          } else {
            return label + " : " + tooltipItem.yLabel;
          }
        },
      },
    },
    hover: {
      intersect: false,
    },
    legend: {
      position: "bottom",
      align: "left",
      reverse: true,
      labels: {
        fontFamily: "Roboto",
        fontSize: 14,
        boxWidth: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        fill: false,
        tension: 0.4,
      },
    },
    scales: {
      xAxes: [
        {
          offset: true,
          labels: datesSevenDay,
          display: true,
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 13,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "right",
          id: "Price",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          labels: {
            show: true,
            labelString: "Price",
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
          },
          scaleLabel: {
            display: true,
            labelString: "Price",
            fontColor: "#777777",
            fontFamily: "Roboto",
            fontSize: 14,
          },
        },
        {
          type: "linear",
          display: true,
          position: "left",
          id: "Occupancy",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            beginAtZero: true,

            stepSize: 25,
            max: 100,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
            callback: function (value) {
              return value + "%";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Occupancy%",
            fontColor: "#777777",
            fontFamily: "Roboto",
            fontSize: 14,
          },
        },
      ],
    },
  };
  const priceOptionsForFourteenDay = {
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: {
        left: 8,
        right: 8,
        top: 0,
        bottom: 0,
      },
    },
    tooltips: {
      intersect: false,

      itemSort: function (a, b) {
        return b.datasetIndex - a.datasetIndex;
      },
      bodyFontFamily: "Roboto",
      backgroundColor: "white",
      bodyFontColor: "#777777",
      bodyFontSize: 14,

      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label.includes("%")) {
            return label + " : " + tooltipItem.yLabel + "%";
          }

          if (tooltipItem.yLabel === 0) {
            return label + ": Sold Out";
          } else {
            return label + " : " + tooltipItem.yLabel;
          }
        },
      },
    },
    hover: {
      intersect: false,
    },

    legend: {
      position: "bottom",
      align: "left",
      reverse: true,
      labels: {
        fontFamily: "Roboto",
        fontSize: 14,
        boxWidth: 10,
      },
    },
    elements: {
      hover: {
        intersect: false,
      },
      point: {
        radius: 0,
      },

      line: {
        fill: false,
        tension: 0.4,
      },
    },
    scales: {
      xAxes: [
        {
          offset: true,
          labels: datesFourteenDay,
          display: true,
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 13,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "right",
          id: "Price",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          labels: {
            show: true,
            labelString: "Price",
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
          },
          scaleLabel: {
            display: true,
            labelString: "Price",
            fontColor: "#777777",
            fontFamily: "Roboto",
            fontSize: 14,
          },
        },
        {
          type: "linear",
          display: true,
          position: "left",
          id: "Occupancy",
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            beginAtZero: true,

            stepSize: 25,
            max: 100,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
            callback: function (value) {
              return value + "%";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Occupancy%",
            fontColor: "#777777",
            fontFamily: "Roboto",
            fontSize: 14,
          },
        },
      ],
    },
  };

  const weeklyOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: true,
      fontFamily: "Roboto",
      fontSize: 20,
      fontColor: "#777777",
      fontStyle: "normal",
    },
    legend: {
      position: "bottom",
      align: "left",
      labels: {
        fontFamily: "Roboto",
        fontSize: 14,
        boxWidth: 10,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 13,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            color: "grey",
            zeroLineColor: "grey",
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Roboto",
            fontColor: "#777777",
            fontSize: 14,
          },
        },
      ],
    },
  };

  let occupancyOptions = JSON.parse(JSON.stringify(weeklyOptions));
  occupancyOptions.title = {
    display: true,

    fontFamily: "Roboto",
    fontSize: 15,
    fontColor: "#777777",
    fontStyle: "bold",
  };
  occupancyOptions.scales = {
    xAxes: [
      {
        gridLines: {
          display: true,
          drawTicks: true,
          drawOnChartArea: false,
          color: "grey",
          zeroLineColor: "grey",
        },
        ticks: {
          fontFamily: "Roboto",
          fontColor: "#777777",
          fontSize: 13,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          drawTicks: true,
          drawOnChartArea: false,
          color: "grey",
          zeroLineColor: "grey",
        },
        ticks: {
          beginAtZero: true,

          stepSize: 25,
          max: 100,
          fontFamily: "Roboto",
          fontColor: "#777777",
          fontSize: 14,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    ],
  };
  occupancyOptions.tooltips = {
    bodyFontFamily: "Roboto",

    backgroundColor: "white",
    bodyFontColor: "#777777",
    bodyFontSize: 14,
    titleFontColor: "#777777",
    callbacks: {
      label: function (tooltipItems, data) {
        return tooltipItems.yLabel + "%";
      },
    },
  };
  let arrOptions = JSON.parse(JSON.stringify(weeklyOptions));
  arrOptions.title = {
    display: true,

    fontFamily: "Roboto",
    fontSize: 15,
    fontColor: "black",
    fontStyle: "bold",
  };
  arrOptions.tooltips = {
    bodyFontFamily: "Roboto",

    backgroundColor: "white",
    bodyFontColor: "#777777",
    bodyFontSize: 14,
    titleFontColor: "#777777",
  };
  let revenueOptions = JSON.parse(JSON.stringify(weeklyOptions));
  revenueOptions.title = {
    display: true,

    fontFamily: "Roboto",
    fontSize: 15,
    fontColor: "black",
    fontStyle: "bold",
  };

  revenueOptions.tooltips = {
    bodyFontFamily: "Roboto",

    backgroundColor: "white",
    bodyFontColor: "#777777",
    bodyFontSize: 14,
    titleFontColor: "#777777",
  };
  useEffect(() => {
    if (!token && !hotelId) {
      return;
    }
    refreshDashboard();
    setIsComparitive(false);
  }, [token, hotelId]);

  useEffect(() => {
    if (!token) {
      return;
    } else {
      if (selectedDate) {
        WeeklySelectedReports();
      }
    }
  }, [token, hotelId, selectedDate]);

  useEffect(() => {
    if (!token) {
      return;
    } else {
      if (isComparitive && comparitiveDate) {
        WeeklyComparitiveReports();
      } else {
        setComparitiveData({});
      }
    }
  }, [token, hotelId, comparitiveDate, isComparitive]);

  useEffect(() => {
    if (!selectedData) {
      return;
    }
    SetupWeeklyData();
  }, [selectedData, comparitiveData, token, hotelId]);

  useEffect(() => {
    if (data) {
      PickupSetup();
      PriceSetup();
    } else {
      return;
    }
  }, [data, token, hotelId]);

  useEffect(() => {
    PriceSetup();
  }, [idx, idxNew]);

  async function refreshYearOptions() {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/years-past-data`,
    });
    const { data } = await get();
    if (data.years.length > 0) {
      data.years.reverse();
      setYearOptions(data.years);
    }
  }
  useEffect(() => {
    refreshYearOptions();
  }, [token]);

  async function refreshDashboard() {
    setLoading(true);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/dashboard`,
    });

    const { data } = await post({ year: parseInt(selectedYear) });
    if (data) {
      if (data?.messageToUser) {
        setOpen(true);
        setNetworkMsg(data.messageToUser);
      }
      if (data.message == "No Data available for Today and Yesterday") {
        setHaveData(false);
        setError(false);
        setData(data);
      } else {
        setHaveData(true);
        setError(false);
        setData(data);
      }
    } else {
      setHaveData(false);
      setError(false);
      setData({});
    }
    setLoading(false);
  }

  useEffect(() => {
    refreshDashboard();
  }, [selectedYear]);

  function PickupSetup() {
    if (data.pickup && data.pickup.length > 0) {
      let pickup;
      pickup = {
        labels: data.pickup.map((data) => {
          return data.month;
        }),
        datasets: [
          {
            label: "24 Hours",
            data: data.pickup.map((data) => {
              return parseInt(data.yesterday);
            }),

            backgroundColor: "#5DA3FB",
            borderColor: "#5DA3FB",
            borderWidth: 4,
          },
          {
            label: "3 Days",
            data: data.pickup.map((data) => {
              return parseInt(data.threeDay);
            }),
            backgroundColor: "#637BF4",
            borderColor: "#637BF4",
            borderWidth: 4,
          },
          {
            label: "7 Days",
            data: data.pickup.map((data) => {
              return parseInt(data.sevenDay);
            }),
            backgroundColor: "#F6E753",
            borderColor: "#F6E753",
            borderWidth: 4,
          },
        ],
      };
      setPickup(pickup);
    }
  }
  function PriceSetup() {
    let priceArray = [];
    let priceArrayNew = [];
    let priceArrayData = [];
    let ThirtyDayArray = [];
    let ThirtyDayArrayNew = [];
    let dateArray = [];
    const dateFormat = (daynew) => {
      let month = parseInt(new Date(daynew).getMonth() + 1);
      if (month < 10) {
        month = "0" + month;
      }
      let dateNo = parseInt(new Date(daynew).getDate());
      if (dateNo < 10) {
        dateNo = "0" + dateNo;
      }
      return dateNo + " " + MONTHSARRAY[month - 1];
    };
    let competitorNameArray = [];
    if (data.competitors) {
      const competiotrs = data.competitors.map((comp) => {
        const ThirtyDayIndexNew = comp.datePrice.findIndex((comp) => {
          if (
            new Date(comp.date).getDate() === new Date(data.date).getDate() &&
            new Date(comp.date).getMonth() === moveMonth(data.date, 1)
          ) {
            return comp;
          }
        });

        ThirtyDayArrayNew =
          ThirtyDayIndexNew !== -1
            ? comp.datePrice.slice(0, ThirtyDayIndexNew + 1)
            : comp.datePrice;
        const obj = {};

        competitorNameArray.push({ name: comp.name });

        setComp(competitorNameArray);
        ThirtyDayArrayNew.map((price) => {
          const key = price.date;
          return (obj[key] = price.price === -1 ? 0 : parseInt(price.price));
        });

        return {
          name: comp.name,
          ...obj,
        };
      });
      let OpacityNew1 = [];

      const obj = {};
      competitorNameArray.map((dataComp) => {
        return (obj[dataComp.name] = 1);
      });
      OpacityNew1.push({
        ...obj,
        [data?.hotel?.name + " (Recommended Price)"]: 1,
        [data?.hotel?.name + " (Current Price)"]: 1,
        [data?.hotel?.name + " (Occ%)"]: 1,
      });

      let DataKeyNew1 = [];

      const objNew = {};
      competitorNameArray.map((dataComp) => {
        return (objNew[dataComp.name] = dataComp.name);
      });
      DataKeyNew1.push({
        ...objNew,
        [data?.hotel?.name + " (Recommended Price)"]:
          data?.hotel?.name + " (Recommended Price)",
        [data?.hotel?.name + " (Current Price)"]:
          data?.hotel?.name + " (Current Price)",
        [data?.hotel?.name + " (Occ%)"]: data?.hotel?.name + " (Occ%)",
      });

      if (!isEdit) {
        setEdit(true);

        setOpacity(OpacityNew1[0]);
        setDataKeys(DataKeyNew1[0]);
        setOpacitySevenDays(OpacityNew1[0]);
        setDataKeysSevenDays(DataKeyNew1[0]);
        setOpacityThirtyDays(OpacityNew1[0]);
        setDataKeysThirtyDays(DataKeyNew1[0]);
      }

      for (var key in competiotrs[0]) {
        if (key !== "name") {
          const obj1 = {};
          const competitorArray = competiotrs?.map((hotelCompetitor, b) => {
            const Competitorkey = hotelCompetitor.name;

            return (obj1[Competitorkey] = hotelCompetitor[key]);
          });

          const competitorsData = {
            date: dateFormat(key),
            ...obj1,
          };
          priceArrayNew.push(competitorsData);
        }
      }
      setDataArray(priceArrayNew);

      let index = 0;
      data.competitors.map((competitor) => {
        const ThirtyDayIndex = competitor.datePrice.findIndex((comp) => {
          if (
            new Date(comp.date).getDate() === new Date(data.date).getDate() &&
            new Date(comp.date).getMonth() === moveMonth(data.date, 1)
          ) {
            return comp;
          }
        });
        ThirtyDayArray =
          ThirtyDayIndex !== -1
            ? competitor.datePrice.slice(0, ThirtyDayIndex + 1)
            : competitor.datePrice;
        const nonZeroData = ThirtyDayArray.filter((day) => {
          if (day.price !== 0) return day.price;
        });

        priceArray.push({
          type: "line",
          label: competitor.name,
          data: nonZeroData.map((data) => {
            return data.price === -1 ? 0 : parseInt(data.price);
          }),
          yAxisID: "Price",
          borderColor: priceColorPalette[index],
          backgroundColor: priceColorPalette[index],
        });

        index++;
      });
    }

    if (data.hotel?.datePriceOccupancy) {
      const ThirtyDayIndex = data.hotel.datePriceOccupancy.findIndex(
        (hotel) => {
          if (
            new Date(hotel.date).getDate() === new Date(data.date).getDate() &&
            new Date(hotel.date).getMonth() === moveMonth(data.date, 1)
          ) {
            return hotel;
          }
        }
      );
      if (ThirtyDayIndex !== -1) {
        ThirtyDayArray = data.hotel.datePriceOccupancy.slice(
          0,
          ThirtyDayIndex + 1
        );
      } else {
        ThirtyDayArray = data.hotel.datePriceOccupancy;
      }
      dateArray = ThirtyDayArray.map((day) => {
        let month = parseInt(new Date(day.date).getMonth() + 1);
        if (month < 10) {
          month = "0" + month;
        }
        let dateNo = parseInt(new Date(day.date).getDate());
        if (dateNo < 10) {
          dateNo = "0" + dateNo;
        }
        return dateNo + " " + MONTHSARRAY[month - 1];
      });
      const nonZeroData = ThirtyDayArray.filter((day) => {
        if (day.currentPrice !== 0) return day.currentPrice;
      });

      const remainingDataNew = ThirtyDayArray?.slice(0, 30).map(
        (datanew, pos) => {
          return {
            date: dateFormat(datanew?.date),
            [data.hotel.name + "" + " (Occ%)"]: ThirtyDayArray[pos]?.occupancy,
            [data.hotel.name + " (Recommended Price)"]:
              ThirtyDayArray[pos]?.recommendedPrice === -1
                ? 0
                : parseInt(ThirtyDayArray[pos]?.recommendedPrice),
            [data.hotel.name + " (Current Price)"]:
              ThirtyDayArray[pos]?.currentPrice === -1
                ? 0
                : parseInt(ThirtyDayArray[pos]?.currentPrice),
          };
        }
      );

      const remainingData = priceArrayNew?.map((x, y) => {
        return {
          ...x,
          [data.hotel.name + "" + " (Occ%)"]: ThirtyDayArray[y]?.occupancy,
          [data.hotel.name + " (Recommended Price)"]:
            ThirtyDayArray[y]?.recommendedPrice === -1
              ? 0
              : parseInt(ThirtyDayArray[y]?.recommendedPrice),
          [data.hotel.name + " (Current Price)"]:
            nonZeroData[y]?.currentPrice === -1
              ? 0
              : parseInt(nonZeroData[y]?.currentPrice),
        };
      });
      let OpacityArray = [];
      OpacityArray.push({
        [data?.hotel?.name + " (Recommended Price)"]: 1,
        [data?.hotel?.name + " (Current Price)"]: 1,
        [data?.hotel?.name + " (Occ%)"]: 1,
      });
      let DataKeyArray = [];
      DataKeyArray.push({
        [data?.hotel?.name + " (Recommended Price)"]:
          data?.hotel?.name + " (Recommended Price)",
        [data?.hotel?.name + " (Current Price)"]:
          data?.hotel?.name + " (Current Price)",
        [data?.hotel?.name + " (Occ%)"]: data?.hotel?.name + " (Occ%)",
      });
      if (priceArrayNew.length === 0) {
        setDataArray(remainingDataNew);
        priceArrayData = remainingDataNew;
        if (!isEdit) {
          setEdit(true);

          setOpacity(OpacityArray[0]);
          setDataKeys(DataKeyArray[0]);
          setOpacitySevenDays(OpacityArray[0]);
          setDataKeysSevenDays(DataKeyArray[0]);
          setOpacityThirtyDays(OpacityArray[0]);
          setDataKeysThirtyDays(DataKeyArray[0]);
        }
      } else {
        setDataArray(remainingData);
        priceArrayData = remainingData;
      }

      setHotelName(data?.hotel?.name);

      const objNew = {};
      competitorNameArray.map((dataComp) => {
        return (objNew[dataComp.name?.trim()] = dataComp.name);
      });

      priceArray.push({
        type: "line",
        label: data.hotel.name + " (Current Price)",
        data: nonZeroData.map((data) => {
          return data.currentPrice === -1 ? 0 : parseInt(data.currentPrice);
        }),
        yAxisID: "Price",
        backgroundColor: "#808080",
        borderColor: "#808080",
      });
      priceArray.push({
        type: "line",
        label: data.hotel.name + " (Recommended Price)",
        data: ThirtyDayArray.map((day) => {
          return day.recommendedPrice === -1
            ? 0
            : parseInt(day.recommendedPrice);
        }),
        yAxisID: "Price",
        backgroundColor: "#000066",
        borderColor: "#000066",
      });

      priceArray.push({
        type: "bar",
        label: data.hotel.name + " (Occ%)",
        data: ThirtyDayArray.map((day) => {
          return parseInt(day.occupancy);
        }),
        yAxisID: "Occupancy",
        backgroundColor: "#EBEBEB",
        borderColor: "#EBEBEB",
        borderWidth: 4,
      });
    }

    const thirtyDayPriceArray = priceArrayData;
    setPrice({
      datasets: thirtyDayPriceArray,
    });

    setPriceForFifteenDay({
      datasets: thirtyDayPriceArray,
      labels: dateArray.slice(0, 15),
    });
    setDates(dateArray);
    setDatesFifteenDay(dateArray.slice(0, 15));

    const sevenDayPriceArray = priceArrayData?.slice(0 + idx, 7 + idx);
    let max = 0;
    sevenDayPriceArray.map((data) => {
      Object.values(data).map((number) => {
        if (Number.isInteger(number)) {
          if (number > max) max = number;
        }
      });
    });
    setSevenDayMax(max);
    setPriceForSevenDay({
      datasets: sevenDayPriceArray,
    });

    setDatesSevennDay(dateArray.slice(0 + idx, 7 + idx));
    const fourteenDayPriceArray = priceArrayData?.slice(
      0 + idxNew,
      14 + idxNew
    );
    let maxvalue = 0;
    fourteenDayPriceArray.map((data) => {
      Object.values(data).map((number) => {
        if (Number.isInteger(number)) {
          if (number > maxvalue) maxvalue = number;
        }
      });
    });
    setFourteenDayMax(maxvalue);
    setPriceForFourteenDay({
      datasets: fourteenDayPriceArray,
    });

    setDatesFourteenDay(dateArray.slice(0 + idxNew, 14 + idxNew));
    const twentyOneDayPriceArray = priceArray.map((array) => ({
      ...array,
      data: array.data.slice(0, 30),
    }));
    setPriceForTwentyOneDay({
      datasets: twentyOneDayPriceArray,
      labels: dateArray.slice(0, 21),
    });

    setDatesTwentyOneDay(dateArray.slice(0, 21));
    setGraphDateArray(dateArray);
    setGraphPriceArray(priceArray);
  }

  function DayNames(day) {
    switch (day) {
      case "monday":
        return "Mon";
        break;
      case "tuesday":
        return "Tue";
        break;
      case "wednesday":
        return "Wed";
        break;
      case "thursday":
        return "Thu";
        break;
      case "friday":
        return "Fri";
        break;
      case "saturday":
        return "Sat";
        break;
      default:
        return "Sun";
    }
  }

  async function WeeklySelectedReports() {
    try {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/weekly-report`,
      });
      const { data, error, response } = await post({
        month: getISODate(selectedDate),
      });
      if (response.status === 200 || response.status === 201) {
        setSelectedData(data);
      } else {
        setSelectedData({});
        setNetworkMsg(
          data?.messageToUser ||
            "Data Not Available for " +
              selectedDate.toLocaleString("default", { month: "long" }) +
              " " +
              selectedDate.getFullYear()
        );
        setOpen(true);
      }
    } catch (e) {
      setSelectedData({});
      setNetworkMsg(
        "Data Not Available for " +
          selectedDate.toLocaleString("default", { month: "long" }) +
          " " +
          selectedDate.getFullYear()
      );
    }
  }
  async function WeeklyComparitiveReports() {
    try {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/weekly-report`,
      });
      const { data, response, error } = await post({
        month: getISODate(comparitiveDate),
      });
      if (response.status === 200 || response.status === 201) {
        setComparitiveData(data);
      } else {
        setComparitiveData({});
        setNetworkMsg(
          data?.messageToUser ||
            "Data Not Available for " +
              comparitiveDate.toLocaleString("default", { month: "long" }) +
              " " +
              comparitiveDate.getFullYear()
        );
        setOpen(true);
      }
    } catch (e) {
      setComparitiveData({});
      setNetworkMsg(
        "Data Not Available for " +
          comparitiveDate.toLocaleString("default", { month: "long" }) +
          " " +
          comparitiveDate.getFullYear()
      );
      setOpen(true);
    }
  }

  function SetupWeeklyData() {
    if (Object.entries(selectedData).length !== 0) {
      const occSelKeys = Object.keys(selectedData.occupancy);
      const occData = {
        labels: occSelKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#306FBC",
            borderColor: "#306FBC",
            borderWidth: 4,
            data: occSelKeys.map((keys) => {
              return parseFloat(selectedData.occupancy[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#C9C9C9",
                borderColor: "#C9C9C9",
                borderWidth: 4,
                data: occSelKeys.map((keys) => {
                  return parseFloat(comparitiveData?.occupancy[keys]).toFixed(
                    2
                  );
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      const arrKeys = Object.keys(selectedData.arr);
      const arrData = {
        labels: arrKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,

            backgroundColor: "#306FBC",
            borderColor: "#306FBC",
            borderWidth: 4,
            data: arrKeys.map((keys) => {
              return parseFloat(selectedData.arr[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#C9C9C9",
                borderColor: "#C9C9C9",
                borderWidth: 4,
                data: arrKeys.map((keys) => {
                  return parseFloat(comparitiveData?.arr[keys]).toFixed(2);
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      const revKeys = Object.keys(selectedData.revpar);
      const revData = {
        labels: revKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#306FBC",
            borderColor: "#306FBC",
            borderWidth: 4,
            data: revKeys.map((keys) => {
              return parseFloat(selectedData.revpar[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#C9C9C9",
                borderColor: "#C9C9C9",
                borderWidth: 4,
                data: revKeys.map((keys) => {
                  return parseFloat(comparitiveData?.revpar[keys]).toFixed(2);
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      setOccupancy(occData);
      setArr(arrData);
      setRevpar(revData);
    }
    if (Object.entries(selectedData).length !== 0) {
      const occSelKeys = Object.keys(selectedData.occupancy);
      const occData = {
        labels: occSelKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#306FBC",
            borderColor: "#306FBC",
            borderWidth: 4,
            data: occSelKeys.map((keys) => {
              return parseFloat(selectedData.occupancy[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#C9C9C9",
                borderColor: "#C9C9C9",
                borderWidth: 4,
                data: occSelKeys.map((keys) => {
                  return parseFloat(comparitiveData?.occupancy[keys]).toFixed(
                    2
                  );
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      const arrKeys = Object.keys(selectedData.arr);
      const arrData = {
        labels: arrKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,

            backgroundColor: "#306FBC",
            borderColor: "#306FBC",
            borderWidth: 4,
            data: arrKeys.map((keys) => {
              return parseFloat(selectedData.arr[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#C9C9C9",
                borderColor: "#C9C9C9",
                borderWidth: 4,
                data: arrKeys.map((keys) => {
                  return parseFloat(comparitiveData?.arr[keys]).toFixed(2);
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      const revKeys = Object.keys(selectedData.revpar);
      const revData = {
        labels: revKeys.map((key) => {
          return DayNames(key);
        }),
        datasets: [
          {
            label: `${
              Months[new Date(selectedDate).getMonth()] +
              " " +
              new Date(selectedDate).getFullYear()
            }`,
            backgroundColor: "#306FBC",
            borderColor: "#306FBC",
            borderWidth: 4,
            data: revKeys.map((keys) => {
              return parseFloat(selectedData.revpar[keys]).toFixed(2);
            }),
          },
          Object.entries(comparitiveData).length !== 0
            ? {
                label: `${
                  Months[new Date(comparitiveDate).getMonth()] +
                  " " +
                  new Date(comparitiveDate).getFullYear()
                }`,
                backgroundColor: "#C9C9C9",
                borderColor: "#C9C9C9",
                borderWidth: 4,
                data: revKeys.map((keys) => {
                  return parseFloat(comparitiveData?.revpar[keys]).toFixed(2);
                }),
              }
            : undefined,
        ].filter(Boolean),
      };
      setOccupancy(occData);
      setArr(arrData);
      setRevpar(revData);

      if (occData) {
        const occupancyobj = {};
        const result = occData?.datasets[0]?.data.map((occdata, position) => {
          return {
            [occData?.datasets[0].label]: parseFloat(occdata),
            Day: occData.labels[position],
          };
        });

        const occupancyresult = occData?.datasets[0]?.data.map(
          (occdata, position) => {
            return {
              [occData?.datasets[0]?.label]: parseFloat(occdata),
              Day: occData.labels[position],
              [occData?.datasets[1]?.label]: parseFloat(
                occData?.datasets[1]?.data[position]
              ),
            };
          }
        );

        setOccDataCurrent(occupancyresult);
        setOccDataNew(result);
        setComparitiveDateNew(occData?.datasets[1]?.label);
        setSelectedDate(occData?.datasets[0]?.label);
      }

      if (arrData) {
        const result = arrData?.datasets[0]?.data.map((arrdata, position) => {
          return {
            [arrData?.datasets[0]?.label]: parseFloat(arrdata),
            Day: arrData.labels[position],
          };
        });

        const arrresult = arrData?.datasets[0]?.data.map(
          (arrdata, position) => {
            return {
              [arrData?.datasets[0]?.label]: parseFloat(arrdata),
              Day: arrData.labels[position],
              [arrData?.datasets[1]?.label]: parseFloat(
                arrData?.datasets[1]?.data[position]
              ),
            };
          }
        );
        setArrDataCurrent(arrresult);
        setArrDataNew(result);
      }
      if (revData) {
        const result = revData?.datasets[0]?.data.map((revdata, position) => {
          return {
            [revData?.datasets[0]?.label]: parseFloat(revdata),
            Day: revData.labels[position],
          };
        });
        const revPARResult = revData?.datasets[0]?.data.map(
          (revdata, position) => {
            return {
              [revData?.datasets[0]?.label]: parseFloat(revdata),
              Day: revData.labels[position],
              [revData?.datasets[1]?.label]: parseFloat(
                revData?.datasets[1]?.data[position]
              ),
            };
          }
        );
        setRevParDataCurrent(revPARResult);

        setRevParDataNew(result);
      }
    }
  }

  const handleCloseStatus = () => {
    setOpen(false);
  };

  return {
    topdata: data,
    pickup: pickup,
    pickupOptions: pickupOptions,
    price: price,
    priceForFifteenDay: priceForFifteenDay,
    priceOptions: priceOptions,
    priceOptionsForFifteenDay: priceOptionsForFifteenDay,
    dates: dates,
    occupancy: occupancy,
    arr: arr,
    revpar: revpar,
    weeklyOptions: weeklyOptions,
    occupancyOptions: occupancyOptions,
    arrOptions: arrOptions,
    revenueOptions: revenueOptions,
    open: open,
    handleCloseStatus: handleCloseStatus,
    priceForSevenDay: priceForSevenDay,
    priceOptionsForSevenDay: priceOptionsForSevenDay,
    priceForFourteenDay: priceForFourteenDay,
    priceOptionsForFourteenDay: priceOptionsForFourteenDay,
    priceForTwentyOneDay: priceForTwentyOneDay,

    idx: idx,
    setIndex: setIndex,
    idxNew: idxNew,
    setIndexNew: setIndexNew,
    comp1: comp1,
    priceColorPalette: priceColorPalette,
    hotelName: hotelName,
    opacity: opacity,
    setOpacity: setOpacity,
    dataKeys: dataKeys,
    setDataKeys: setDataKeys,
    opacitySevenDays: opacitySevenDays,
    setOpacitySevenDays: setOpacitySevenDays,
    dataKeysSevenDays: dataKeysSevenDays,
    setDataKeysSevenDays: setDataKeysSevenDays,
    opacityThirtyDays: opacityThirtyDays,
    setOpacityThirtyDays: setOpacityThirtyDays,
    dataKeysThirtyDays: dataKeysThirtyDays,
    setDataKeysThirtyDays: setDataKeysThirtyDays,
    occDataNew: occDataNew,
    arrDataNew: arrDataNew,
    revParDataNew: revParDataNew,
    occDataCurrent: occDataCurrent,
    comparitiveDateNew: comparitiveDateNew,
    selectedDateNew: selectedDateNew,
    arrDataCurrent: arrDataCurrent,
    revParDataCurrent: revParDataCurrent,
    sevenDayMax: sevenDayMax,
    fourteenDayMax: fourteenDayMax,
    selectedYear: selectedYear,
    setSelectedYear: setSelectedYear,
    yearOptions: yearOptions,
  };
}
