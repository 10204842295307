import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography, styled } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useState } from 'react'
import { useAuth } from '../../sdk';

const PermissionModal = ({ open, handleClose, selectRoleData, setSelectedRoleData,
    permissionList, editPermission, isLoading, getRolesAndPermissions, setnetworkMsg
}) => {
    const { authFetch } = useAuth();

    const [arr, setArr] = useState([]);
    const [isSaving, setIsSaving] = useState(false)

    const handleCheckBoxClick = useCallback((item, e) => {
        const checked = e.target.checked;

        const index = arr.findIndex(data => data.permissionId === item.id);

        if (index !== -1) {
            const updatedArr = [...arr];
            updatedArr[index] = { ...updatedArr[index], isAdded: checked };
            setArr(updatedArr);
        } else {
            setArr(prevArr => [
                ...prevArr,
                { roleId: selectRoleData?.roleId, permissionId: item.id, isAdded: checked }
            ]);
        }

        const updatedPermissions = checked ?
            [...selectRoleData.permissions, { permissionId: item.id, permissionName: item.name }] :
            selectRoleData.permissions.filter(data => data.permissionId !== item.id);

        setSelectedRoleData({ ...selectRoleData, permissions: updatedPermissions });

    }, [arr, selectRoleData, setSelectedRoleData]);

    const handleSavePermissions = useCallback(async () => {
        try {
            setIsSaving(true)
            const { put } = await authFetch({
                path: `/update-permission`,
            });
            const { response } = await put(arr);
            if (response.status === 200) {
                handleClose()
                setnetworkMsg({
                    open: true,
                    msg: 'Saved Successfully',
                    color: 'green'
                })
                getRolesAndPermissions()
            }

        } catch (error) {
            console.log(error);
            setnetworkMsg({
                open: true,
                msg: 'Something went wrong',
                color: 'red'
            })
            handleClose()
        } finally {
            setIsSaving(false)
        }
    }, [arr, authFetch, getRolesAndPermissions, handleClose, setnetworkMsg])



    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >

            <DialogTitle className="title">Role Name : {selectRoleData?.roleName}</DialogTitle>
            <Typography fontSize='24px' className="title" sx={{ textDecoration: 'underline' }}>{editPermission ? 'Edit Permission List' : 'Permission List'}</Typography>

            <CloseIcon className="closeButton" onClick={handleClose} />

            {isLoading ?
                <Box textAlign='center' >
                    <CircularProgress />
                </Box> :
                <DialogContent className="content">
                    {editPermission ?
                        (
                            permissionList?.map((item, index) => (
                                <Stack key={index} direction='row' alignItems='center'>
                                    <Checkbox
                                        checked={selectRoleData?.permissions?.some(data => data.permissionId === item.id)}
                                        onChange={(e) => handleCheckBoxClick(item, e)}
                                    />
                                    <Typography sx={{ textTransform: 'capitalize' }}>{item?.name}</Typography>
                                </Stack>
                            )
                            )
                        )
                        : (
                            selectRoleData?.permissions?.map((item, index) => (
                                <Stack key={index} direction='row' alignItems='center'>
                                    <Typography sx={{ textTransform: 'capitalize' }}>{item?.permissionName}</Typography>
                                </Stack>
                            ))
                        )}


                </DialogContent>}
            {editPermission &&
                <Stack direction='row' gap={8} padding={4} justifyContent='center'>
                    <Button variant='contained' onClick={handleSavePermissions} disabled={isSaving || isLoading}>
                        Save</Button>
                    <Button variant='contained' onClick={handleClose}>Close</Button>
                </Stack>
            }



        </StyledModal>
    )
}

export default PermissionModal

const StyledModal = styled(Dialog)(() => ({
    padding: '16px',
    ".closeButton": {
        position: "absolute",
        right: "10px",
        top: "10px",
        cursor: "pointer",
    },
    ".title": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textTransform: 'capitalize'

    },
    ".content": {
        display: "flex",
        flexDirection: "column",
        paddingLeft: '20%',
        maxHeight: '500px',
    },

}));
