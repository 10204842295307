import React, { useEffect, useMemo, useState } from "react";
import {
  HeaderCard,
  LabelNew,
  MainContainer,
  P,
  StyledCalenderWrapper,
  SubText,
} from "./styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import {
  CalendarToday,
  Map,
  ReportProblem,
  SaveAlt,
  ViewList,
} from "@mui/icons-material";
import UseEventDetails from "./useEventDetails";
import { useAuth } from "../sdk";
import DatePicker from "react-modern-calendar-datepicker";
import EventDetailTable from "./EventDetailTable";
import MapView from "./MapView";

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  top: "50%";
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 98;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;
const marks = [
  {
    value: 0,
    label: "1 km",
  },
  {
    value: 100,
    label: "100 km",
  },
];

export default function EventDetails({ setPageHeader }) {
  const { currentHotel } = useAuth();

  useEffect(() => {
    setPageHeader("All Events");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  const {
    eventTypes,
    selectedType,
    setSelectedType,
    radius,
    setRadius,
    view,
    setView,
    fromDateNew,
    toDateNew,
    setFromDateNew,
    settoDateNew,
    eventDetails,
    error,
    count,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    selectedRows,
    setSelectedRows,
    handleAdd,
    networkMsg,
    setnetworkMsg,
    searchInput,
    setSearchInput,
    searchKeyword,
    setSearchKeyword,
    options,
    selectedKeywords,
    setSelectedKeywords,
    setPage,
    downloadFile,
  } = UseEventDetails();
  const { permission } = useAuth();
  let addUpcomingEvents = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "addUpcomingEvents") {
        addUpcomingEvents = permission[key];
      }
    }
  }

  const googleMapMarker = useMemo(() => {
    return {
      eventDetails: eventDetails?.event_details?.events?.map((val) => ({
        id: val.id,
        name: val.eventname,
        position: {
          lat: Number(val.latitude),
          lng: Number(val.longitude),
        },
        venue: val.venue,
        address: val.address,
        date: val.startdate,
        color: val.color,
      })),
      competitorDetails: eventDetails?.competitors_details?.map((val) => ({
        id: val.id,
        name: val.name,
        position: {
          lat: Number(val.latitude),
          lng: Number(val.longitude),
        },
      })),
    };
  }, [eventDetails]);

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };
  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };
  const handleChangeNew = (nextView) => {
    setView(nextView);
  };
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const handleEventChange = (eventId) => {
    setSelectedType((prev) => {
      const isSelected = prev?.some((row) => row === eventId);
      if (isSelected) {
        return prev.filter((row) => row !== eventId);
      } else {
        return [...prev, eventId];
      }
    });
    setPage(0);
  };

  return (
    <MainContainer direction={"row"}>
      <Stack className="leftPanel">
        <Stack className="leftContent">
          <Typography mt={2} className="hotelName">
            {currentHotel?.name}
          </Typography>
          <Typography mt={1} className="address">
            {currentHotel?.address}
          </Typography>
          <Stack mt={3} gap={2}>
            <Autocomplete
              multiple
              limitTags={2}
              options={options?.keywordDetails ?? []}
              getOptionLabel={(option) => option.name}
              defaultValue={[]}
              value={selectedKeywords}
              onChange={(e, value) => {
                setSelectedKeywords(value);
                setPage(0);
              }}
              onInputChange={(e, newInputValue) =>
                setSearchKeyword(newInputValue)
              }
              sx={{
                borderRadius: "20px",
                marginBottom: "10px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Search"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                      padding: "6px 10px !important",
                      display: "flex",
                      justifyContent: "center",
                    },
                  }}
                />
              )}
            />
            <Stack gap={1} className="dateContainer">
              <P>Date</P>
              <Stack display="flex" alignItems="center" gap={1}>
                <HeaderCard>
                  <LabelNew>From</LabelNew>
                  <label>
                    <StyledCalenderWrapper>
                      <DatePicker
                        value={fromDateNew}
                        onChange={(date) => {
                          setFromDateNew(date);
                          setPage(0);
                        }}
                        renderInput={renderCustomInput}
                        calendarClassName="myCustomCalendar"
                        calendarPopperPosition="bottom"
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  </label>
                </HeaderCard>
                <HeaderCard>
                  <LabelNew>To</LabelNew>
                  <label>
                    <StyledCalenderWrapper
                      sx={{
                        marginLeft: "15px",
                      }}
                    >
                      <DatePicker
                        value={toDateNew}
                        onChange={settoDateNew}
                        renderInput={renderCustomInputToDate}
                        calendarClassName="myCustomCalendar"
                        calendarPopperPosition="bottom"
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  </label>
                </HeaderCard>
              </Stack>
            </Stack>
            <Stack gap={2} className="dateContainer">
              <P>Event Types</P>
              <Box mt={1} px={2}>
                <FormGroup>
                  {eventTypes.map((val) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={selectedType}
                          onChange={() => handleEventChange(val?.id)}
                          checked={selectedType?.some((row) => row === val?.id)}
                        />
                      }
                      label={val?.name}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Stack>
            <Stack gap={2} className="dateContainer">
              <Stack gap={2}>
                <P>Radius</P>
                <SubText>1Km - 100Km</SubText>
              </Stack>
              <Box display="flex" justifyContent="center">
                <Slider
                  value={radius}
                  marks={marks}
                  valueLabelDisplay="auto"
                  onChange={(e, val) => {
                    setRadius(val);
                    setPage(0);
                  }}
                  sx={{
                    width: "80%",
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box className="viewContent">
        {error ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={"100vh"}
          >
            <Typography
              display={"flex"}
              alignItems={"center"}
              textTransform={
                error === "failed to check hotel location url"
                  ? "none"
                  : "capitalize"
              }
            >
              <ReportProblem color="error" />{" "}
              <Typography ml={1}>
                {error === "failed to check hotel location url"
                  ? "Hotel Location URL is not available"
                  : error}
              </Typography>
            </Typography>
          </Box>
        ) : (
          <>
            <Box display="flex" gap={2} justifyContent="end" py={2}>
              <IconButton onClick={downloadFile} className="downloadBtn">
                <SaveAlt />
              </IconButton>
              <ToggleButtonGroup
                value={view}
                exclusive
                color="primary"
                style={{
                  height: "32px",
                  boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <ToggleButton
                  value="map"
                  aria-label="map"
                  onClick={() => handleChangeNew("map")}
                >
                  <Map style={{ color: "#163a90" }} />
                </ToggleButton>
                <ToggleButton
                  value="table"
                  aria-label="table"
                  onClick={() => handleChangeNew("table")}
                >
                  <ViewList style={{ color: "#163a90" }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {view === "table" ? (
              <>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                >
                  <TextField
                    label="Search"
                    variant="outlined"
                    placeholder="Search by Event Name, Address, Location Etc."
                    size="small"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e?.target?.value ?? "");
                      setPage(0);
                    }}
                    sx={{
                      width: "500px",
                      "& .MuiInputBase-root": {
                        borderRadius: "45px",
                      },
                    }}
                  />
                  {addUpcomingEvents && (
                    <Button
                      disabled={selectedRows?.length === 0}
                      onClick={handleAdd}
                      className="eventButton"
                    >
                      Add to Event Calendar
                    </Button>
                  )}
                </Stack>
                <EventDetailTable
                  eventDetails={eventDetails}
                  count={count}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  permission={addUpcomingEvents}
                />
              </>
            ) : (
              <MapView
                markers={googleMapMarker}
                radius={radius}
                mapContainerStyle={{
                  width: "100%",
                  height: "74vh",
                }}
                currentHotel={currentHotel}
                center={{
                  lat: Number(eventDetails?.hotel_details?.latitude),
                  lng: Number(eventDetails?.hotel_details?.longitude),
                }}
              />
            )}
          </>
        )}
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={networkMsg}
        autoHideDuration={3000}
        onClose={() => setnetworkMsg(false)}
      >
        {networkMsg && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsg === "Event Added Successfully"
                  ? "#228b22"
                  : "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </MainContainer>
  );
}
