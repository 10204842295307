import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { KeyButton } from "./styles";
import { Close } from "@mui/icons-material";
import { format, parseISO } from "date-fns";

export default function ComposeDialog({
  openDialog,
  setOpenDialog,
  handleSaveMessage,
  setNewMessage,
  newMessage,
}) {
  const Quill = ReactQuill.Quill;
  var Font = Quill.import("formats/font");
  Font.whitelist = [
    "Roboto",
    "Arial",
    "Georgia",
    "Raleway",
    "Tahoma",
    "Impact",
    "Montserrat",
    "Lato",
    "Rubik",
  ];
  Quill.register(Font, true);

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
        setNewMessage({
          title: "",
          description: "",
        });
      }}
      maxWidth="sm"
      fullWidth
    >
      <IconButton
        disableRipple
        sx={{
          position: "absolute",
          top: "8px",
          right: "10px",
        }}
        onClick={() => {
          setOpenDialog(false);
          setNewMessage({
            title: "",
            description: "",
          });
        }}
      >
        <Close />
      </IconButton>

      <DialogTitle>Create Message</DialogTitle>
      <DialogContent>
        <Stack gap={2} mt={1} component="form">
          <TextField
            value={newMessage?.title}
            onChange={(e) =>
              setNewMessage((prev) => ({
                ...prev,
                title: e?.target?.value,
              }))
            }
            fullWidth
            variant="outlined"
            label="Title"
          />
          <Typography>Message Description</Typography>
          <ReactQuill
            theme="snow"
            style={{
              height: "250px",
              display: "flex",
              flexDirection: "column",
            }}
            onChange={(value) =>
              setNewMessage((prev) => ({
                ...prev,
                description: value,
              }))
            }
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"],
                [{ font: Font.whitelist }],

                [
                  {
                    size: ["small", false, "large", "huge"],
                  },
                ],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ align: ["", "center", "right", "justify"] }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                ["clean"],
              ],
            }}
            formats={[
              "font",
              "size",

              "script",
              "bold",
              "italic",
              "indent",
              "align",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "link",
            ]}
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          mr: "16px",
          mb: "8px",
        }}
      >
        <KeyButton
          disabled={!newMessage?.title || !newMessage?.description}
          onClick={handleSaveMessage}
        >
          Send
        </KeyButton>
      </DialogActions>
    </Dialog>
  );
}
