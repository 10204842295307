import { ArrowForwardIos, SwapVert } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Button,
  IconButton,
  Menu,
  Paper,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import moment from "moment";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  DatePickerSection,
  Mainpaper,
  SelectHeader,
  StyledDialog,
  StyledExport,
} from "../Price Checker/Styles";
import { AddEmail } from "../Price Checker/components/AddEmail";
import { MonthPicker } from "../sdk/components/MonthPicker";
import ComparisonTable from "./components/ComparisonTable";
import useChannelComparision from "./components/hooks/useChannelComparision";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as XDatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/system";
import { Nodata } from "../sdk";

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: "8px !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
    backgroundColor: "white !important",
  },
}));

const sources = ["Desktop", "Mobile"];
const nights = ["1 Night", "2 Nights", "3 Nights"];
const guests = ["1 Guest", "2 Guests"];

const ChannelComparison = ({ setPageHeader }) => {
  useEffect(() => {
    setPageHeader("Rate Parity");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  const {
    fromDateNew,
    setFromDateNew,
    toDateNew,
    settoDateNew,
    addEmailDialog,
    setAddEmailDialog,
    errorSnackbar,
    errorMsg,
    setErrorMsg,
    setErrorSnackbar,
    selectedMonthYear,
    setSelectedMonthYear,
    renderCustomInput,
    renderCustomInputToDate,
    tableData,
    channels,
    oldestRate,
    isLoading,
    filters,
    setFilters,
    canPostRateMetric,
    handleCloseAddEmailDialog,
    handleIncrementDecrementDate,
    showSkeleton,
    monthlyPendingCredits,
    fileExport,
    openCustom,
    setOpenCustom,
    openCustomDialog,
    closeCustomDialog,
    openExport,
    exportFileMenuOpen,
    exportFileMenuClose,
    anchorEl,
    exportFile,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    mobileChannels,
  } = useChannelComparision();

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  const sourcesToShow = useMemo(() => {
    if (filters?.sourceName[0] === sources[1]) return mobileChannels;

    return channels;
  }, [channels, filters.sourceName, mobileChannels]);

  const optionsToShow = useMemo(() => {
    if (channels.length && mobileChannels.length) return sources;
    else if (channels.length) return [sources[0]];

    return [sources[1]];
  }, [channels.length, mobileChannels.length]);

  const handleExportTimeline = useCallback(
    (name) => {
      const currentDate = new Date();
      exportFileMenuClose();
      switch (true) {
        case name === "Next 60 Days":
          currentDate.setDate(currentDate.getDate() + 60);
          exportFile(new Date(), currentDate);
          break;
        case name === "Next 90 Days":
          currentDate.setDate(currentDate.getDate() + 90);
          exportFile(new Date(), currentDate);
          break;
        case name === "Selected Month":
          exportFile(
            new Date(
              selectedMonthYear.getFullYear(),
              selectedMonthYear.getMonth(),
              1
            ),
            new Date(
              selectedMonthYear.getFullYear(),
              selectedMonthYear.getMonth() + 1,
              0
            )
          );
          break;
        case name === "Custom":
          openCustomDialog();
          break;
        default:
          break;
      }
    },
    [exportFile, exportFileMenuClose, openCustomDialog, selectedMonthYear]
  );

  return (
    <ClassWrapper>
      <AddEmail
        open={addEmailDialog}
        handleClose={handleCloseAddEmailDialog}
        renderCustomInput={renderCustomInput}
        renderCustomInputToDate={renderCustomInputToDate}
        fromDateNew={fromDateNew}
        setFromDateNew={setFromDateNew}
        toDateNew={toDateNew}
        settoDateNew={settoDateNew}
        handleSubmit={canPostRateMetric}
      />
      <Box
        sx={{
          paddingTop: "60px",
          marginLeft: "40px",
          background: "#fff",
          height: "100%",
        }}
      >
        <Mainpaper elevation={0}>
          <Stack
            direction={"row"}
            justifyContent={"space-around"}
            gap={{ md: 1, lg: 2 }}
            padding="0px 10px 0px 10px"
          >
            <Stack
              sx={{
                padding: "4px 12px 9px 12px",
                display: "flex",
                flexDirection: "row",
                height: "55px",
                gap: {
                  md: 1,
                  lg: 2,
                },
                justifyContent: "space-between",
                borderRadius: "8px",
              }}
            >
              <Paper
                sx={{
                  padding: "4px 12px 9px 12px",
                  height: "40px",
                  width: "250px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
                elevation={2}
              >
                <Stack direction={"row"}>
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrementDate("left");
                    }}
                  >
                    <ArrowBackIosIcon
                      style={{ fontSize: "14px", color: "rgb(48,111,188)" }}
                    />
                  </IconButton>
                  <MonthPicker
                    value={selectedMonthYear}
                    onChange={setSelectedMonthYear}
                    disablePastMonths
                  />
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrementDate("right");
                    }}
                  >
                    <ArrowForwardIos
                      style={{ fontSize: "14px", color: "rgb(48,111,188)" }}
                    />
                  </IconButton>
                </Stack>
              </Paper>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={{ md: 1, lg: 2 }}
              >
                <SelectHeader size="small">
                  {/* <InputLabel>Source</InputLabel> */}
                  <Select
                    variant="standard"
                    value={filters.sourceName}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        sourceName: [e.target.value],
                        channelIds:
                          e.target.value === sources[1]
                            ? mobileChannels.map((channel) => channel?.sourceId)
                            : channels.map((channel) => channel?.sourceId),
                      });
                    }}
                    renderValue={(selected) => selected}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {optionsToShow?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters.sourceName.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <SelectHeader size="small">
                  {/* <InputLabel>Channel</InputLabel> */}
                  <Select
                    variant="standard"
                    multiple
                    value={filters.channelIds}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length === 0) {
                        return;
                      }
                      // const querySources =
                      //   value?.includes("all") && value.length > 1
                      //     ? "all"
                      //     : value.join("-");
                      // searchParams.set("sources", querySources);
                      // history.replace({
                      //   pathname: history.location.pathname,
                      //   search: searchParams.toString(),
                      // });
                      if (value?.includes("all")) return;
                      const removeAll = value?.filter((item) => item !== "all");
                      setFilters((previous) => {
                        return {
                          ...previous,
                          channelIds: removeAll,
                        };
                      });
                    }}
                    renderValue={(selected) => {
                      if (!sourcesToShow) {
                        return "Loading...";
                      }
                      if (sourcesToShow.length === 0) {
                        return "None";
                      }

                      if (selected?.length === sourcesToShow?.length) {
                        return "All";
                      }
                      if (selected?.length > 1) {
                        const firstSelectedChannel = sourcesToShow?.find(
                          (channel) => channel?.sourceId === selected?.[0]
                        );
                        return `${firstSelectedChannel?.name}...`;
                      }
                      return selected?.map((sourceId, index) => {
                        const channel = sourcesToShow?.find(
                          (channel) => channel?.sourceId === sourceId
                        );
                        return (
                          channel?.name +
                          (selected?.length - 1 === index ? " " : ", ")
                        );
                      });
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                      minWidth: 100,
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                      disableCloseOnSelect: true,
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        setFilters((p) => {
                          if (p.channelIds.length === sourcesToShow?.length) {
                            return {
                              ...p,
                              channelIds: [],
                            };
                          }
                          return {
                            ...p,
                            channelIds: sourcesToShow?.map(
                              (item) => item.sourceId
                            ),
                          };
                        });
                      }}
                      key="all"
                      value="all"
                      disabled={
                        filters.channelIds?.length !== 0 &&
                        filters.channelIds.length === sourcesToShow?.length
                      }
                    >
                      <Checkbox
                        sx={{ color: "#163A90" }}
                        checked={
                          filters.channelIds?.length !== 0 &&
                          filters.channelIds.length === sourcesToShow?.length
                        }
                      />
                      All
                    </MenuItem>
                    {sourcesToShow?.map((channel) => (
                      <MenuItem key={channel.sourceId} value={channel.sourceId}>
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters.channelIds.includes(
                            channel.sourceId
                          )}
                        />
                        <ListItemText primary={channel.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <SelectHeader size="small">
                  {/* <InputLabel>LOS</InputLabel> */}
                  <Select
                    variant="standard"
                    value={filters.nightName}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        nightName: [e.target.value],
                      });
                      searchParams.set("los", e.target.value[0]);
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => selected}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {nights?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters.nightName.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <SelectHeader size="small">
                  {/* <InputLabel>No. of Guests</InputLabel> */}
                  <Select
                    variant="standard"
                    value={filters.guestName}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        guestName: [e.target.value],
                      });
                      searchParams.set("occupancy", e.target.value[0]);
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => selected}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {guests?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters.guestName.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <StyledExport
                  id="export-file-button"
                  title="Export file"
                  aria-controls={openExport ? "export-file-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openExport ? "true" : undefined}
                  onClick={exportFileMenuOpen}
                >
                  <img src="/assets/Export.svg" alt="export" />
                </StyledExport>
                <Menu
                  id="export-file-menu"
                  aria-labelledby="export-file-button"
                  anchorEl={anchorEl}
                  open={openExport}
                  onClose={exportFileMenuClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {fileExport?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      onClick={() => handleExportTimeline(name)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>

              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#306FBC",
                    fontSize: "16px",
                    width: "220px",
                  }}
                >
                  Your {monthlyPendingCredits} Rateshop credits are remaining
                  for this month{" "}
                </Typography>
              </Stack>
            </Stack>

            {/* <MonthPicker
                                value={selectedMonthYear}
                                onChange={setSelectedMonthYear}
                            /> */}

            {/* <Paper
                            elevation={2}
                            style={{
                                padding: "4px 12px 9px 12px",
                                height: "40px",
                                width: "507px",
                                boxShadow:
                                    "0px 4px 4px rgba(48, 111, 188, 0.13)",
                                borderRadius: "8px",
                            }}
                        >
                            <Autocomplete
                                options={hotelList?.map((item) => item.name)}
                                noOptionsText={"No Hotels Available"}
                                style={{ width: "100%", font: "Roboto" }}
                                onChange={handleHotelSelect}
                                value={
                                    selectedHotel
                                        ? selectedHotel.name
                                        : currentHotel?.name
                                }
                                classes={{
                                    paper: classes.dropdownStyle,
                                }}
                                popupIcon={
                                    <KeyboardArrowDownIcon
                                        style={{ color: "#306FBC" }}
                                    />
                                }
                                disableClearable
                                renderInput={(params) => (
                                    <>
                                        <Stack direction={"row"}>
                                            <SearchIcon
                                                style={{
                                                    color: "#306FBC",
                                                    position: "absolute",
                                                }}
                                            />
                                            <TextField
                                                variant="standard"
                                                sx={{
                                                    ml: "40px",
                                                    "& input": {
                                                        color: "#000",
                                                    },
                                                }}
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    placeholder: selectedHotel
                                                        ? selectedHotel.name
                                                        : currentHotel?.name,
                                                }}
                                            />
                                        </Stack>
                                    </>
                                )}
                            />
                        </Paper> */}
          </Stack>
        </Mainpaper>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          pl={"113px"}
        >
          <Box
            sx={{
              marginLeft: "3%",
              marginTop: "80px",
            }}
          >
            {!!oldestRate && oldestRate !== "0001-01-01T00:00:00Z" && (
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "-1%",
                  color: "#949494",
                }}
              >
                Latest updated at {moment(oldestRate).fromNow()}{" "}
              </Typography>
            )}
          </Box>
        </Stack>
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 70px 10px 6px",
            margin: "0 auto",
          }}
        >
          {/* <ComparisonGraph
            channels={channels.filter(({ sourceId }) =>
              filters.channelIds.find((id) => id === sourceId)
            )}
            tableData={tableData?.data}
            selectedMonthYear={selectedMonthYear}
          /> */}
          <ComparisonTable
            channels={sourcesToShow?.filter(({ sourceId }) =>
              filters.channelIds.find((id) => id === sourceId)
            )}
            tableData={tableData?.data}
            isLoading={isLoading}
            showSkeleton={showSkeleton}
          />
          <Stack gap="20px">
            <Box
              variant="contained"
              title="Refresh"
              // disabled={!clickonRefreshBtn}
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "100%",
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 10px 2px rgba(3, 4, 94, 0.2)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ":hover": {
                  backgroundColor: "lightblue",
                },
              }}
              onClick={() => setAddEmailDialog(true)}
            >
              <SwapVert
                sx={{
                  color: "blue",
                }}
              ></SwapVert>
            </Box>
          </Stack>
        </Paper>
      </Box>
      <StyledDialog
        PaperProps={{
          className: "paperProps",
        }}
        open={openCustom}
        onClose={closeCustomDialog}
      >
        <>
          <Box className="dialogHeader">
            <Typography className="dialogTitle">
              RateShopping: Select Date
            </Typography>
          </Box>

          <Box padding={3}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography className="dateHeading">Start Date</Typography>
              <Typography className="dateHeading">End Date</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePickerSection>
                  <XDatePicker
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    onChange={(date) => setStartDate(new Date(date))}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          svg: { color: "#163A90" },
                          width: "234px",
                        }}
                        variant="outlined"
                        {...params}
                      />
                    )}
                    sx={{ height: "35px" }}
                  />

                  <XDatePicker
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputFormat="DD/MM/YYYY"
                    value={endDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          svg: { color: "#163A90" },
                          width: "234px",
                        }}
                        variant="outlined"
                        {...params}
                      />
                    )}
                    sx={{ height: "35px" }}
                    onChange={(date) => setEndDate(new Date(date))}
                  />
                </DatePickerSection>
              </LocalizationProvider>
            </Stack>

            <Stack justifyContent="space-evenly" direction="row" mt={3}>
              <Button
                variant="outlined"
                onClick={closeCustomDialog}
                className="cancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="confirmButton"
                onClick={async () => {
                  await exportFile(startDate, endDate);
                  closeCustomDialog();
                }}
              >
                Confirm
              </Button>
            </Stack>
          </Box>
        </>
      </StyledDialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setErrorSnackbar(false);
          setErrorMsg("");
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={errorMsg}
        />
      </Snackbar>
    </ClassWrapper>
  );
};

export default ChannelComparison;
