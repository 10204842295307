import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../sdk";
import { format } from "date-fns";

const websites = [
  "All",
  "Expedia",
  "Brand Website",
  "Agoda",
  "Booking.com",
  "MakeMy Trip",
];
const sources = ["Desktop"];
const nights = ["1 Night", "2 Nights", "3 Nights"];
const guests = ["1 Guest", "2 Guests"];
const fileExport = ["Next 60 Days", "Next 90 Days", "Selected Month", "Custom"];

export default function useChannelComparision() {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const defaultFromValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const defaultToValue = {
    year: lastDay.getFullYear(),
    month: lastDay.getMonth() + 1,
    day: lastDay.getDate(),
  };

  const [fromDateNew, setFromDateNew] = useState(defaultFromValue);
  const [toDateNew, settoDateNew] = useState(defaultToValue);

  const [addEmailDialog, setAddEmailDialog] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const abortRef = useRef(null);

  const [selectedMonthYear, setSelectedMonthYear] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  useEffect(() => {
    const currentDate = new Date();

    const firstdayOfMonth =
      selectedMonthYear.getMonth() === currentDate.getMonth() &&
      selectedMonthYear.getFullYear() === currentDate.getFullYear()
        ? currentDate.getDate()
        : 1;

    const lastDayOfMonth = new Date(
      selectedMonthYear.getFullYear(),
      selectedMonthYear.getMonth() + 1,
      0
    ).getDate();

    setFromDateNew({
      day: firstdayOfMonth,
      month: selectedMonthYear.getMonth() + 1,
      year: selectedMonthYear.getFullYear(),
    });
    settoDateNew({
      day: lastDayOfMonth,
      month: selectedMonthYear.getMonth() + 1,
      year: selectedMonthYear.getFullYear(),
    });
  }, [selectedMonthYear]);

  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const [tableData, setTableData] = useState({ competitors: [], data: [] });
  const [channels, setChannels] = useState([]);
  const [mobileChannels, setMobileChannels] = useState([]);
  const { hotelId } = useParams();
  const [oldestRate, setOldestRate] = useState(null);

  const {
    hotels,
    token,
    authFetch,
    permission,
    selectHotel,
    updateHotels,
    currentHotel,
  } = useAuth();

  const [monthlyPendingCredits, setMonthlyPendingCredits] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [clickonRefreshBtn, setClickonRefreshBtn] = React.useState(true);
  const openRefreshDialog = () => {
    setOpen(true);
  };

  const closeRefreshDialog = () => {
    setOpen(false);
  };

  const [hotelList, setHotelList] = useState([]);
  const [differentOrganization, setDifferentOrganization] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState("");

  const [openCustom, setOpenCustom] = React.useState(false);
  const openCustomDialog = () => {
    setOpenCustom(true);
  };

  const closeCustomDialog = () => {
    setOpenCustom(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [togglePage, setTogglePage] = useState(false);

  const [filters, setFilters] = useState({
    channelIds: [],
    sourceName: [sources?.[0].toString()],
    nightName: [nights?.[0].toString()],
    guestName: [guests?.[0].toString()],
    exportName: "",
  });

  let listOfOrg = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
    }
  }

  const getSources = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/sources`,
    });
    const { data, error } = await get();
    if (data) {
      const newData = [...data];
      setChannels(newData.filter((data) => !data?.isMobile));
      setMobileChannels(newData.filter((data) => data?.isMobile));
    } else {
      console.log(error);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    if (token) {
      getSources();
    }
  }, [token, getSources]);

  //api to get sources name

  const formatDate = useCallback((date) => {
    const year = new Date(date).getFullYear();
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
    const day = new Date(date).getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }, []);

  const getDatesArray = useCallback((startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);
    while (currentDate <= endDateObj) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      dates.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }, []);

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isCalledOnce = useRef(false);

  useEffect(() => {
    const queryDate = searchParams.get("date");
    const parsedDate = new Date(queryDate);

    const year = selectedMonthYear.getFullYear();
    const month = selectedMonthYear.getMonth();
    const date = new Date(year, month, 1);
    const finalDate = format(date, "yyyy-MM-dd");
    !queryDate && searchParams.set("date", finalDate);
    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });

    queryDate && setSelectedMonthYear(parsedDate);
  }, []);

  const getRateShop = useCallback(async () => {
    setIsLoading(true);

    if (filters?.channelIds?.length === 0) {
      setTableData({ competitors: [], data: [] });
      setIsLoading(false);
      return;
    }

    const { get, abort } = await authFetch({
      path: `/hotel/${hotelId}/rate-shop`,
    });
    abortRef.current = abort;
    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;
    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

    const today = new Date();
    let startDate;
    let endDate;

    if (
      selectedMonthYear.getFullYear() === today.getFullYear() &&
      selectedMonthYear.getMonth() === today.getMonth()
    ) {
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 30
      );
    } else {
      startDate = new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth(),
        1
      );
      endDate = new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth() + 1,
        0
      );
    }

    const start = formatDate(startDate);
    const end = formatDate(endDate);

    const body = {
      startDate: start,
      endDate: end,
      sources: filters.channelIds.includes("all")
        ? filters.channelIds
            ?.filter((channelId) => channelId !== "all")
            .join(",")
        : filters.channelIds.join(","),
      los: los?.toString(),
      occupancy: occupancy?.toString(),
      hotelOnly: true,
    };

    const { data, error } = await get(`?${new URLSearchParams(body)}`);
    if (data && data.statusCode !== 500) {
      const allDates = getDatesArray(startDate, endDate);
      setOldestRate(data.oldestRate);
      setMonthlyPendingCredits(data?.monthlyPendingCredits);

      data.finalResult = data?.finalResult?.map((item) => {
        item.hotelResult = item?.hotelResult?.reduce(
          (acc, hotel, index) => {
            acc.sourceSortedArray = [
              ...acc?.sourceSortedArray,
              ...hotel?.sourceSortedArray,
            ];
            return acc;
          },
          { sourceSortedArray: [] }
        );

        return item;
      });

      const competitors = data?.finalResult?.find(
        (data) => data.competitorsResults
      )?.competitorsResults;
      const paddedData = allDates?.map((date, index) => {
        const matchingData =
          data &&
          data?.finalResult?.find(
            (d) => d.rateDate.slice(0, 10) === date.slice(0, 10)
          );
        if (matchingData) {
          return {
            ...matchingData,
          };
        } else {
          return {
            rateDate: date,
            minHotelPrice: "",
            meanCompetitorPrice: "",
            hotelResult: {
              hotelId: "",
              hotelName: "",
              minPrice: "",
              sourceSortedArray: channels?.map(({ id }) => ({
                sourceId: id,
                price: null,
              })),
            },
            competitorsResults: {
              hotelId: "",
              hotelName: "",
              minPrice: "",
              sourceSortedArray: channels?.map(({ id }) => ({
                sourceId: id,
                price: null,
              })),
            },
          };
        }
      });

      setTableData({
        competitors,
        data: paddedData?.map((data) => ({
          ...data,
          rateDate: formatDate(data.rateDate),
          competitorsResults: competitors?.map(() => {
            return {
              hotelId: "",
              hotelName: "",
              minPrice: null,
              sourceSortedArray: channels?.map(({ id }) => ({
                sourceId: id,
                price: null,
              })),
            };
          }),
        })),
      });
    } else {
      console.log(error);
    }
    setIsLoading(false);
  }, [
    authFetch,
    filters,
    formatDate,
    getDatesArray,
    hotelId,
    selectedMonthYear,
    channels,
  ]);
  const getRateShopDetails = useCallback(async () => {
    isCalledOnce.current = true;

    const queryLos = searchParams.get("los");
    const queryOccupancy = searchParams.get("occupancy");

    setFilters({
      ...filters,
      channelIds: channels?.map((item) => item?.sourceId),
      guestName: guests.filter((item) =>
        queryOccupancy ? item[0] === queryOccupancy : item[0] === guests?.[0][0]
      ),
      nightName: nights?.filter((item) =>
        queryLos ? item[0] === queryLos : item[0] === nights?.[0][0]
      ),
    });

    !queryLos && searchParams.set("los", nights?.[0][0]);
    !queryOccupancy && searchParams.set("occupancy", guests?.[0][0]);

    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  }, [channels, filters, history, searchParams]);

  useEffect(() => {
    if (!token || !channels.length) return;
    getRateShopDetails();
  }, [channels.length, token]);

  const canPostRateMetric = async (values) => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/can-rate-metric`,
    });

    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;

    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

    const horizonrange = `${
      fromDateNew.year
    }/${fromDateNew.month
      ?.toString()
      ?.padStart(2, "0")}/${fromDateNew.day?.toString()?.padStart(2, "0")}-${
      toDateNew.year
    }/${toDateNew.month
      .toString()
      .padStart(2, "0")}/${toDateNew.day?.toString()?.padStart(2, "0")}`;
    const horizon = toDateNew.day - fromDateNew.day + 1;

    const requestBody = {
      sources: filters.channelIds,
      los: los || null,
      occupancy: occupancy || null,
      horizonrange,
      horizon,
      hotelOnly: false,
    };

    try {
      const { data, response } = await post(requestBody);
      if (response.status === 200) {
        postRateMetric({
          email: values?.email,
          refreshDate: values?.refreshDate,
        });
      } else {
        setErrorSnackbar(true);
        setErrorMsg(data?.messageToUser || "Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postRateMetric = async (values) => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/rate-metric`,
    });

    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;

    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

    const horizonrange = `${
      fromDateNew.year
    }/${fromDateNew.month
      ?.toString()
      ?.padStart(2, "0")}/${fromDateNew.day?.toString()?.padStart(2, "0")}-${
      toDateNew.year
    }/${toDateNew.month
      .toString()
      .padStart(2, "0")}/${toDateNew.day?.toString()?.padStart(2, "0")}`;
    const horizon = toDateNew.day - fromDateNew.day + 1;

    const requestBody = {
      sources: filters.channelIds,
      los: los || null,
      occupancy: occupancy || null,
      horizonrange,
      horizon,
      hotelOnly: true,
      email: values?.email,
    };

    try {
      const { data } = await post(requestBody);
      if (data?.messageToUser) {
        setErrorSnackbar(true);
        setErrorMsg(data?.messageToUser || "Something went wrong");
      }
      getRateShop();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isCalledOnce.current) return;
    if (
      filters.channelIds.length ||
      filters.guestName.length ||
      filters.nightName.length ||
      filters.sourceName.length
    ) {
      getRateShop();
    }
  }, [
    filters.guestName,
    filters.channelIds,
    filters.nightName,
    filters.sourceName,
  ]);

  useEffect(() => {
    if (!isCalledOnce.current) return;
    getRateShopDetails();
  }, [selectedMonthYear]);

  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    let timeoutId;
    const fetchData = async () => {
      timeoutId = setInterval(async () => {
        setShowSkeleton(false);
        await getRateShop();
        setShowSkeleton(true);
      }, 30 * 1000);
    };
    fetchData();
    return () => clearInterval(timeoutId);
  }, [getRateShop]);

  const handleCloseAddEmailDialog = () => {
    setAddEmailDialog(false);
  };

  const handleIncrementDecrementDate = useCallback(
    (tag) => {
      const year = selectedMonthYear.getFullYear();
      const month = selectedMonthYear.getMonth();
      let date = new Date(year, month, 1);

      if (tag === "left") {
        date.setMonth(date.getMonth() - 1);
      } else if (tag === "right") {
        date.setMonth(date.getMonth() + 1);
      }

      const parsedDate = format(date, "yyyy-MM-dd");
      const newSearchParams = new URLSearchParams();
      newSearchParams.set("date", parsedDate);

      history.replace({
        pathname: history.location.pathname,
        search: newSearchParams.toString(),
      });

      setSelectedMonthYear(date);
    },
    [history, selectedMonthYear]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const openExport = Boolean(anchorEl);

  const exportFileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportFileMenuClose = () => {
    setAnchorEl(null);
  };

  const exportFile = useCallback(
    async (startDate, endDate) => {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-parity/excel`,
      });
      const losIndex = nights.indexOf(filters.nightName?.[0]);
      const los = losIndex >= 0 ? losIndex + 1 : null;
      const guestsIndex = guests.indexOf(filters.guestName?.[0]);
      const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;
      const channelIds = filters.channelIds.map((id) => String(id));
      const sources = channelIds.join(",");
      const body = {
        los: los?.toString(),
        occupancy: occupancy?.toString(),
        hotelOnly: false,
        endDate: formatDate(endDate),
        startDate: formatDate(startDate),
      };

      const response = await get(
        `?${new URLSearchParams(body)}&sources=${sources}`
      );
      const blob = await response.response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${formatDate(startDate)}-${formatDate(endDate)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
    [filters, hotelId, guests, nights, authFetch]
  );
  return {
    lastDay,
    fromDateNew,
    setFromDateNew,
    toDateNew,
    settoDateNew,
    addEmailDialog,
    setAddEmailDialog,
    errorSnackbar,
    setErrorSnackbar,
    abortRef,
    selectedMonthYear,
    setSelectedMonthYear,
    formatInputValue,
    formatInputValueToDate,
    renderCustomInput,
    renderCustomInputToDate,
    tableData,
    setTableData,
    channels,
    setChannels,
    hotelId,
    oldestRate,
    setOldestRate,
    isLoading,
    open,
    clickonRefreshBtn,
    setClickonRefreshBtn,
    openRefreshDialog,
    closeRefreshDialog,
    hotelList,
    setHotelList,
    differentOrganization,
    setDifferentOrganization,
    selectedHotelId,
    setSelectedHotelId,
    openCustom,
    setOpenCustom,
    openCustomDialog,
    closeCustomDialog,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    togglePage,
    setTogglePage,
    filters,
    setFilters,
    listOfOrg,
    formatDate,
    getDatesArray,
    getRateShop,
    canPostRateMetric,
    postRateMetric,
    getSources,
    handleCloseAddEmailDialog,
    handleIncrementDecrementDate,
    showSkeleton,
    monthlyPendingCredits,
    fileExport,
    openExport,
    exportFileMenuOpen,
    exportFileMenuClose,
    anchorEl,
    exportFile,
    mobileChannels,
    errorMsg,
    setErrorMsg,
  };
}
