import {
  Box,
  Divider,
  InputLabel,
  Select as SL,
  Table as TB,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseRounded, Email, Schedule } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Card, GhostButton as GB, PrimaryButton } from "../sdk";

export const GhostButton = styled(GB)`
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
  height: 100%;
  &:disabled {
    cursor: auto;
  }
`;
export const Select = styled(SL)`
  .MuiSelect-root {
    color: #281e53;
    font: bold 16px/20px Roboto;
    height: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }
`;

export const RecommendationPage = styled(Box)`
  padding: 80px 0px 0px 80px;
  width: 99%;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
`;

export const HeaderCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #281e53;
  button.MuiButtonBase-root {
    opacity: 1;
    font: normal normal bold 16px / 20px Roboto;

    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;

      padding-left: 6px;
    }
  }
`;

export const Hr = styled(Divider)`
  color: #f5f4f8;
  width: 1px;
  height: 30px;
`;

export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;
export const Container = styled(Box)`
  overflow-y: hidden;
  height: calc(100vh - 152px);
`;
export const ScheduleIconDisabled = styled(Schedule)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const EmailIconDisabled = styled(Email)`
  &:hover {
    cursor: not-allowed;
  }
`;
export const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
`;

export const Theader = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: top;
    text-align: left;
    font: normal normal normal 8px/10px Roboto;
    letter-spacing: 0px;
    color: #210d79;
    border: none;
    padding: 5px;
    width: 100px;
  }
`;

export const Tdata = styled(TableCell)`
  &.MuiTableCell-body {
    text-align: left;
    font: normal normal normal 12px/15px Roboto;
    letter-spacing: 0px;
    color: #3c3c3c;
    border: none;
    padding: 5px;
  }
`;
export const CalcHeader = styled(Box)`
  display: flex;
  width: 100%;
  padding: 5px;
  margin-right: 10px;
`;
export const Text = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #3c3c3c;
  margin-right: 28px;
`;
export const DayText = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #0a0a0a;
  margin-right: 20px;
`;

export const PriceText = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  letter-spacing: 0px;
  color: #3c3c3c;
  margin-right: 8px;
  display: inline;
`;
export const Price = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #0a0a0a;
`;
export const Tr = styled(TableRow)``;
export const Thead = styled(TableHead)``;
export const Tbody = styled(TableBody)``;

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: fit-content;
`;
export const Forecast = styled(Typography)`
  font: normal normal 600 16px/20px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;
export const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;
export const Head = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const Label = styled(InputLabel)`
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  text-align: left;
`;
export const UploadSection = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const InputField = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-end;
`;

export const Done = styled(PrimaryButton)`
  width: 80px;
  height: 28px;
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 13px/16px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 15px 0 5px 0;
`;
export const TextFieldBig = styled(TextField)`
  width: 286px;
  input.MuiInputBase-input {
    padding: 6px;
    font: 500 16px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
  }
`;

export const Mybtn = styled(PrimaryButton)`
  box-shadow: 0px 3px 6px #0000002f;
  border-radius: 4px;
  width: 88px;
  height: 28px;
  font: normal normal 600 12px/15px Roboto;
  color: #ffffff;
`;
export const CancelBtn = styled(PrimaryButton)`
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 4px;
  border: none;
  width: 88px;
  height: 28px;
  margin-right: 31px;
  font: normal normal 600 12px/15px Roboto;
  color: #575757;
`;

export const Div = styled(Box)`
  width: 440px;
  min-height: 239px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #a0a0a0;
  padding: 15px;
`;
export const PopoverHeader = styled(Typography)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #0a0a0a;
  text-align: center;
`;
export const P = styled(Typography)`
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #3c3c3c;
  padding-left: 40px;
  padding-right: 25px;
`;
export const Div1 = styled(Box)`
  display: flex;
  justify-content: center;

  align-items: center;
  width: 100%;
  padding-left: 140px;
`;
export const Div2 = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export const RecommendationTable = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;
export const MyCard = styled(Card)`
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  flex-wrap: nowrap;
`;
export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: center;
    width: 64px;
    height: 20px;
    padding: 0px 10px 10px 10px;
  }
`;
export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
    border: none;
    width: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0px;
    &.MuiTableCell-root {
      padding: 0px 6px;
    }
  }
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 44px;
  width: 64px;
`;
export const NoInc = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const IncDec = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Trow = styled(TableRow)`
  &.MuiTableRow-root {
    height: 42px;
  }
`;
export const DATE = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 200px;
  padding-left: 4px;
  padding-right: 8px;
`;
export const DAY = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  padding-left: 8px;
`;

export const CardWrapper = styled(MyCard)(() => ({
  ".root": {
    width: "100%",
  },
  ".container": {
    height: "calc(100% - 20px)",
    width: "100%",
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",
      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
}));

export const TextOnlyTooltip = styled(Tooltip)(() => ({
  ".tooltip": {
    font: "normal normal normal 12px/16px Roboto",
  },
}));

export const TextOnlyTooltipPopover = styled(Tooltip)(() => ({
  ".tooltip": {
    font: "normal normal normal 12px/16px Roboto",
    marginLeft: -8,
    minWidth: "310px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "15px",
    backgroundColor: "#fff  !important",
    border: "1px solid #A0A0A0",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #A0A0A0",
    },
    color: "#ffffff !important",
    fontSize: 22,
  },
}));

export const TooltipH = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    marginBottom: -10,
    backgroundColor: "#306fbc !important",
  },
  ".arrow": {
    color: "#306fbc !important",
  },
}));

export const TextOnlyTooltipDatePopover = styled(Tooltip)(() => ({
  ".tooltip": {
    font: "normal normal normal 14px/8px Roboto",
    marginLeft: -8,
    width: "auto",

    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "4px",
    background: " #163A90 0% 0% no-repeat padding-box",
    boxShadow: "0px 4px 8px #00000035",
    color: "#ffffff",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#163A90",
  },
}));
