import React from 'react'
import {
    Box,
    Button, Paper, Snackbar, SnackbarContent, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, styled, tableCellClasses
} from '@mui/material'
import useRoleManagement from './hooks/useRoleManagement'
import PermissionModal from './components/PermissionModal';
import AddRoleModal from './components/AddRoleModal';

const RoleManagement = () => {

    const { isLoading, roleList, openPermissionModal, setOpenPermissionModal,
        selectRoleData, setSelectedRoleData, permissionList, getAllPermissions,
        editPermission, setEditPermission, getRolesAndPermissions, networkMsg, setnetworkMsg,
        openAddRoleModal, setOpenAddRoleModal
    } = useRoleManagement()

    return (
        <Box sx={{ padding: '70px 25px 0px 76px' }}>
            <Stack direction='row' justifyContent='end' gap={4} padding={2}>
                <Button variant='contained' onClick={() => {
                    setOpenAddRoleModal(true)
                }}>+ Add Role</Button>
            </Stack>
            <TableContainer sx={{ maxHeight: '80vh' }} component={Paper}>
                <Table stickyHeader >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Serial No.</StyledTableCell>
                            <StyledTableCell>Role Name</StyledTableCell>
                            <StyledTableCell>Permissions</StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {roleList.map((item, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>{index + 1}</StyledTableCell>
                                <StyledTableCell sx={{ textTransform: 'capitalize' }}>
                                    <Typography>
                                        {item?.roleName}
                                    </Typography></StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: '100px', textTransform: 'capitalize' }}>
                                    {item?.permissions ? (
                                        item.permissions.length <= 4 ? (
                                            <>
                                                {item.permissions.map((perm, index) => (
                                                    <Typography key={index} component="div">
                                                        <ul>
                                                            <li>{perm.permissionName}</li>
                                                        </ul>
                                                    </Typography>
                                                ))}
                                                <Button variant="text" onClick={() => {
                                                    getAllPermissions()
                                                    setOpenPermissionModal(true)
                                                    setSelectedRoleData(item)
                                                    setEditPermission(true)
                                                }}>
                                                    Edit
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {item.permissions.slice(0, 3).map((perm, index) => (
                                                    <Typography key={index} component="div">
                                                        <ul>
                                                            <li>{perm.permissionName}</li>
                                                        </ul>
                                                    </Typography>
                                                ))}
                                                <Button variant="text" onClick={() => {
                                                    setOpenPermissionModal(true)
                                                    setSelectedRoleData(item)
                                                    setEditPermission(false)

                                                }}>
                                                    View All
                                                </Button>
                                                <Button variant="text" onClick={() => {
                                                    getAllPermissions()
                                                    setOpenPermissionModal(true)
                                                    setSelectedRoleData(item)
                                                    setEditPermission(true)
                                                }}>
                                                    Edit
                                                </Button>
                                            </>
                                        )
                                    ) : (
                                        "-"
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            {openPermissionModal &&
                <PermissionModal
                    open={openPermissionModal}
                    handleClose={() => setOpenPermissionModal(false)}
                    selectRoleData={selectRoleData}
                    setSelectedRoleData={setSelectedRoleData}
                    permissionList={permissionList}
                    editPermission={editPermission}
                    isLoading={isLoading}
                    getRolesAndPermissions={getRolesAndPermissions}
                    setnetworkMsg={setnetworkMsg}
                />
            }

            {openAddRoleModal &&
                <AddRoleModal
                    open={openAddRoleModal}
                    handleClose={() => setOpenAddRoleModal(false)}
                    getRolesAndPermissions={getRolesAndPermissions}
                    setnetworkMsg={setnetworkMsg}
                />
            }
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={networkMsg.open}
                autoHideDuration={6000}
                onClose={() => {
                    setnetworkMsg({
                        open: false,
                        msg: '',
                        color: ''
                    })
                }}
            >
                <SnackbarContent
                    style={{ backgroundColor: networkMsg.color }}
                    message={networkMsg.msg}
                />
            </Snackbar>

        </Box>

    )
}

export default RoleManagement


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: "#130453",
        font: "normal normal bold 16px/20px Roboto",
        padding: "16px",
        paddingLeft: '64px',
        letterSpacing: "0px",
        width: "11%",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingLeft: '64px',
        width: "11%",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
