import {
  Box,
  Skeleton,
  Stack,
  TableBody,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Table from "@mui/material/Table";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useAuth } from "../../sdk";
import { Page } from "../Styles";
import { format } from "date-fns/esm";

let rows = new Array(25).fill({
  date: "Wed, 01/02/2023",
  brand: 45,
  booking: 345,
  expedia: 781,
});

const ClassWrapper = styled(Box)(() => ({
  ".scrollContainer": {
    overflow: "auto",
    maxHeight: "70vh",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#163A90",
      borderRadius: "6px",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#306fbc",
    color: "white",
  },
}));

const ComparisonTable = ({ channels, tableData, isLoading, showSkeleton }) => {
  const { currentHotel } = useAuth();
  // add index property to each row object
  const newDate = new Date();
  const todaysDate = format(newDate, "yyyy-MM-dd");
  const brandWebsite = channels?.find((channel) => channel?.isBrandWebsite);

  const nonBrandWebsites = channels?.filter(
    (channel) => !channel?.isBrandWebsite
  );
  const sortedChannels = brandWebsite
    ? [brandWebsite, ...nonBrandWebsites]
    : nonBrandWebsites;
  channels = sortedChannels;

  return (
    <Page>
      <ClassWrapper>
        {isLoading && showSkeleton ? (
          [...Array(7)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              height={40}
              width="90%"
              s
              sx={{ mt: 5 }}
            />
          ))
        ) : (
          <Box
            sx={{
              width: "90%",
              borderRadius: "5px",
            }}
          >
            <div className="scrollContainer">
              <Table
                stickyHeader
                sx={{
                  border: "0.5px solid #ABABAB",
                }}
                size="small"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      height: "62px",
                    }}
                  >
                    <StyledTableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid #ABABAB",
                      }}
                    >
                      Date
                    </StyledTableCell>
                    {channels?.map((channel) => {
                      if (channel?.isBrandWebsite) {
                        return (
                          <StyledTableCell align="center" key={channel?.id}>
                            Brand Website
                          </StyledTableCell>
                        );
                      } else {
                        return (
                          <StyledTableCell align="center" key={channel?.id}>
                            {channel?.name}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <StyledTableRow
                      key={row.rateDate}
                      sx={{
                        height: "48px",
                        background: row.index === 0 ? "#DFDFDF" : "",
                        "& td": {
                          color:
                            row.rateDate === todaysDate ? "#306FBC" : "#000000",
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          width: "250px",
                          fontSize: "14px",
                          fontWeight: 600,
                          color:
                            row.rateDate === todaysDate ? "#306FBC" : "#000000",
                          borderRight: "1px solid #ABABAB",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          gap="16px"
                        >
                          {row.rateDate}
                          {row?.isSearching && (
                            <StyledSearching
                              component="img"
                              src="/assets/loading.svg"
                              alt="refreshing"
                              title="Processing"
                            />
                          )}
                        </Stack>
                      </TableCell>
                      {channels
                        ?.map(
                          ({ sourceId }) =>
                            row?.hotelResult?.sourceSortedArray?.find(
                              (channel) => channel?.sourceId === sourceId
                            ) ?? { price: null }
                        )
                        .map((source) => {
                          const brandWebsitePrice = row?.hotelResult?.sourceSortedArray.find(
                            (item) => item?.sourceId === 8
                          )?.price;
                          const higherthanOta = row?.hotelResult?.sourceSortedArray?.some(
                            (item) =>
                              item?.internalStatus === "available" &&
                              brandWebsitePrice > item?.price
                          );
                          return (
                            <TableCell align="center" key={source?.sourceId}>
                              <Typography
                                fontSize="unset"
                                color={
                                  source?.sourceId === 8 &&
                                  source?.internalStatus === "available"
                                    ? higherthanOta
                                      ? "red"
                                      : "green"
                                    : "inherit"
                                }
                              >
                                {source &&
                                source?.internalStatus === "available"
                                  ? currentHotel.RSCurrency === "INR"
                                    ? "₹" + source?.price
                                    : "$" + source?.price
                                  : null}
                                {source && source?.internalStatus === "sold_out"
                                  ? "Sold Out"
                                  : null}
                                {source &&
                                source?.internalStatus === "error" ? (
                                  <Tooltip
                                    color="error"
                                    sx={{
                                      fontSize: "20px",
                                    }}
                                    title={source.errMsg}
                                  >
                                    <ReportProblemIcon />
                                  </Tooltip>
                                ) : null}
                                {!source.price && source?.price !== 0
                                  ? "--"
                                  : null}
                              </Typography>
                            </TableCell>
                          );
                        })}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Box>
        )}
      </ClassWrapper>
    </Page>
  );
};

export default ComparisonTable;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(116, 116, 116, 0.2)",
  },
  "& td": {
    border: 0,
  },
}));

const StyledSearching = styled(Box)(() => ({
  height: "30px",
  aspectRatio: "1/1",
  animation: "spin 1s infinite linear",

  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));
