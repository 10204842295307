import React from "react";
import { Stack } from "@mui/system";
import { Box, IconButton, Typography, styled } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import useBroadcastDetail from "./useBroadcastDetail";
import Markdown from "markdown-to-jsx";
import { KeyboardBackspace } from "@mui/icons-material";
import { format } from "date-fns";

export default function BroadcastDetail() {
  const { Id, hotelId } = useParams();
  const { details } = useBroadcastDetail({ Id });
  const history = useHistory();

  return (
    <MainContainer>
      <IconButton
        disableRipple
        onClick={() => {
          history.push(`/hotel/${hotelId}/broadcast-messages`);
        }}
        sx={{
          padding: "0px",
          color: "#808080",
          mb: "16px",
        }}
      >
        <KeyboardBackspace fontSize="large" />
      </IconButton>
      <Stack direction={"row"} gap={2} width={"100%"} height={"100%"}>
        <Stack
          width={"70%"}
          gap={2}
          className="contentContainer scrollContainer"
        >
          <Typography mb={1} className="heading" fontSize={18} fontWeight={600}>
            Broadcast Message
          </Typography>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Typography fontSize={16} fontWeight={600}>
              Title
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              {details?.createdAt
                ? format(new Date(details?.createdAt), "dd MMMM yy hh:mm a")
                : ""}
            </Typography>
          </Stack>
          <Typography>{details?.title}</Typography>

          <Typography fontSize={16} fontWeight={600}>
            Description
          </Typography>
          <Typography>
            <Markdown>{details?.description}</Markdown>
          </Typography>
        </Stack>
        <Stack
          width={"30%"}
          sx={{
            paddingTop: "0px !important",
          }}
          className="contentContainer scrollContainer"
        >
          <Typography
            display={"flex"}
            justifyContent={"center"}
            className="heading"
            fontSize={18}
            fontWeight={600}
            sx={{
              position: "sticky",
              zIndex: 10,
              backgroundColor: "white",
              top: 0,
              paddingTop: "16px",
              paddingBottom: "4px",
              width: "100%",
            }}
          >
            Recipients
          </Typography>
          <Stack mt={3} gap={"20px"}>
            {details?.users?.map((item) => (
              <Stack gap={"3px"}>
                <Typography fontWeight={500}>{item?.name}</Typography>
                <Typography fontWeight={300}>{item?.email}</Typography>
              </Stack>
            ))}
            {details?.users?.map((item) => (
              <Stack gap={"3px"}>
                <Typography fontWeight={500}>{item?.name}</Typography>
                <Typography fontWeight={300}>{item?.email}</Typography>
              </Stack>
            ))}
            {details?.users?.map((item) => (
              <Stack gap={"3px"}>
                <Typography fontWeight={500}>{item?.name}</Typography>
                <Typography fontWeight={300}>{item?.email}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </MainContainer>
  );
}

export const MainContainer = styled(Box)((theme) => ({
  padding: "80px 16px 16px 80px",
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "start",
  ".contentContainer": {
    background: "#ffffff 0% 0% no-repeat padding-box",
    padding: "16px 20px",
    height: "100%",
    ".heading": {
      color: "#163A90",
      fontFamily: "Roboto",
    },
  },
  ".scrollContainer": {
    overflowY: "auto",

    maxHeight: "calc(100vh - 155px)",
  },
}));
