import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  getISODate,
  onUniqueProp,
  useAuth,
  usePrevious,
} from "./../../sdk";

export function useBaseDemandLevel(
  setCopyFromHotelId,
  setCheckbox,
  setIsChangeDl,
  isChangeDL,
  dLCheckbox,
  updateSelectedDL,
  selectDL,
  startDate,
  endDate,
  checkbox,
  copyFromHotelId = null,
  setCopyModalOpen,
  setHaveData,
  setLoading,
  getData
) {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [baseDemandLevels, setBaseDemandLevels] = useState([]);
  const [demandLevels, setDemandLevels] = useState([]);
  const saveButtonClicked = usePrevious(isInEditMode);
  const { hotelId } = useParams();
  const { token, authFetch } = useAuth();
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const currentHotelId = hotelId;
  const [isApiHit, setApiHit] = useState(false);
  const [renderIteration, setRenderIteration] = useState(0);
  const [selectedDate, setSelectedDate] = useState(() => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  });
  let res = {};

  const handleCloseStatus = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (!token) return;
    setLoading(true);
    Promise.allSettled([fetchDemandLevels()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, hotelId]);
  useEffect(() => {
    if (saveButtonClicked && !isInEditMode) {
      updateBaseDemandLevels();
    }
  }, [isInEditMode]);
  useEffect(() => {
    (async () => {
      if (copyFromHotelId === null) return;
      await fetchAnotherHotelBaseDemandLevels();
    })();
  }, [copyFromHotelId]);

  async function updateBaseDemandLevels(bdls = baseDemandLevels) {
    setLoading(true);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/base-demand-level/all`,
    });
    const { data, response, error } = await post(
      bdls.filter(isValid).map((bDL) => {
        return {
          ...bDL,
          date: getISODate(bDL.date),
        };
      })
    );
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setOpen(true);
      console.log(error);
    }
    getData();
    setLoading(false);
  }

  async function fetchDemandLevels(anotherHotelId = false) {
    let dLevels = [];
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/city-demand-level`,
    });
    const { data, error } = await get();
    if (data) {
      dLevels = data;
    } else {
      console.log(error);
    }
    setDemandLevels(dLevels);
  }
  function isValid(bDL) {
    return bDL.demandLevelSymbol !== null;
  }
  function onChange(index, value) {
    setBaseDemandLevels((prevState) => {
      return prevState.map((row, idx) =>
        idx === index
          ? {
              ...value,
            }
          : row
      );
    });
  }

  async function fetchAnotherHotelBaseDemandLevels() {
    setLoading(true);
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/base-demand-level/copy`,
    });

    const { response: res, error } = await post({
      hotelId: copyFromHotelId,
      isCityDemandLevel: updateSelectedDL?.CityDemandLevel,
      isCityEvent: updateSelectedDL?.CityEvent,
      isDateRange: checkbox,
      fromDate: checkbox ? startDate : null,
      toDate: checkbox ? endDate : null,
    });

    if (res?.data?.message?.length > 0) {
      setnetworkMsg(res?.data?.message);
      setOpen(true);
    } else if (res?.data?.messageToUser?.length > 0) {
      setnetworkMsg(res?.data?.messageToUser);
      setOpen(true);
    }

    if (error) {
      console.log(error);
    }
    setLoading(false);
    setIsChangeDl(false);
    setCheckbox(false);
    setCopyFromHotelId(null);
  }

  function generateEmptyDemandLevelForNext6Months() {
    const today = new Date();
    today.setHours(5, 30, 0, 0);
    return Array(183)
      .fill(null)
      .map((_, index) => {
        const date = new Date();
        date.setHours(5, 30, 0, 0);
        date.setDate(today.getDate() + index);
        return {
          demandLevelSymbol: null,
          cityDemandLevelSymbol: null,
          date: date,
          displayDate: date.toLocaleDateString("en-GB"),
          description: "",

          month: new Date(date).getMonth(),
          year: new Date(date).getFullYear(),
        };
      });
  }

  function generateBaseDemandLevelRows(bDLs) {
    return [
      ...bDLs.map((bDL, index) => ({
        ...bDL,
        date: new Date(bDL.date),
        displayDate: new Date(bDL.date).toLocaleDateString("en-GB"),
        month: new Date(bDL.date).getMonth(),
        year: new Date(bDL.date).getFullYear(),
      })),
      ...generateEmptyDemandLevelForNext6Months(),
    ]
      .filter(onUniqueProp("displayDate"))
      .sort((a, b) => {
        const [dateA, monthA, yearA] = a.displayDate.split("/");
        const [dateB, monthB, yearB] = b.displayDate.split("/");
        return (
          Date.parse(`${monthA}/${dateA}/${yearA}`) -
          Date.parse(`${monthB}/${dateB}/${yearB}`)
        );
      });
  }
  async function setBaseDemandLevelsfromModel(bdls) {
    setLoading(true);
    await fetch(`${BASE_URL}/hotel/${hotelId}/base-demand-level/set`, {
      headers: {
        token,
      },
      method: "POST",
      body: JSON.stringify(
        bdls.filter(isValid).map((bDL) => {
          return {
            ...bDL,
            date: getISODate(bDL.date),
          };
        })
      ),
    });

    setLoading(false);
  }
  async function generateBatchBaseDemandLevels(
    optionsArray,
    fromEvent = false
  ) {
    const dateDemandLevelMap = {};
    for (const row of optionsArray) {
      const {
        startDate,
        endDate,
        days,
        demandLevelSymbol,
        hotelDemandLevelSymbol,
        cityDemandLevelSymbol,
        description,
        events,
        manualEvents,
        overrideSymbol,
        cityOverrideSymbol,
      } = row;
      const numberOfDaysFromStartToEndDate =
        (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          (1000 * 3600 * 24) +
        1;
      const numberOfDaysFromStartToEndDateINT = Math.round(
        numberOfDaysFromStartToEndDate
      );

      for (let i = 0; i < numberOfDaysFromStartToEndDateINT; i++) {
        const dateInContext = new Date(startDate);
        dateInContext.setDate(dateInContext.getDate() + i);
        const dayOfDateInContext = dateInContext.getDay();
        const isDaySelected =
          days.findIndex((d) => dayOfDateInContext === d) !== -1;
        if (isDaySelected) {
          const displayDate = dateInContext.toLocaleDateString("en-GB");
          dateDemandLevelMap[displayDate] = {
            date: dateInContext,
            displayDate,
            demandLevelSymbol,
            hotelDemandLevelSymbol,
            cityDemandLevelSymbol,
            description,
            events,
            manualEvents,
            overrideSymbol,
            cityOverrideSymbol,
          };
        }
      }
    }
    if (!fromEvent) {
      await setBaseDemandLevelsfromModel(Object.values(dateDemandLevelMap));
      return;
    }
    const overridedDemandLevelsMergedWithAlreadyExistingData = [
      ...Object.values(dateDemandLevelMap),
      ...baseDemandLevels,
    ]
      .filter(onUniqueProp("displayDate"))
      .sort((a, b) => {
        const [dateA, monthA, yearA] = a.displayDate.split("/");
        const [dateB, monthB, yearB] = b.displayDate.split("/");
        return (
          Date.parse(`${monthA}/${dateA}/${yearA}`) -
          Date.parse(`${monthB}/${dateB}/${yearB}`)
        );
      });
    await updateBaseDemandLevels(
      overridedDemandLevelsMergedWithAlreadyExistingData
    );
  }

  return {
    currentHotelId,
    open,
    handleCloseStatus,
    networkMsg,
    baseDemandLevels,
    demandLevels,
    isInEditMode,
    toggleEdit: () => setIsInEditMode(!isInEditMode),
    onChange,
    generateBatchBaseDemandLevels,
    isApiHit,
    setApiHit,
    setRenderIteration,
    renderIteration,
    setSelectedDate,
    selectedDate,
  };
}
