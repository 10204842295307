import {
  Box,
  TextField as TF,
  Typography,
  AppBar as AB,
  Container,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import { Edit as ED, Forum, List, People } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../hooks";
import { Sidebar } from "./Sidebar";
const TextField = styled(TF)`
  &.MuiFormControl-root {
    width: 300px;
  }
  input {
    width: 100%;
    color: #fff;
    text-align: center;
    font: normal normal bold 18px Roboto;
    display: inline-block;
    padding: 0 0 0 20px;
  }
`;

const LogoContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
  width: ${(props) => (props.toggle ? "260px" : "60px")};
`;
const Logo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  &:hover {
    cursor: pointer;
  }
`;
const Title = styled(Typography)`
  text-align: left;
  font: normal normal normal 25px/30px Cormorant Garamond;
  color: #ffffff;
  padding-left: 4px;
`;
const Nav = styled(AB)`
  display: flex;
  background: #163a90 0% 0% no-repeat padding-box;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;
const HotelName = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #0f2662;
  border-radius: 10px;
  height: 36px;
`;
const LogoutIconNew = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  width: 28px;
  border-radius: 50%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  margin-left: 14px;
`;
const UserIcon = styled(Box)`
  padding: 4px;
  background: #112d75 0% 0% no-repeat padding-box;
  width: 40px;
  border-radius: 50%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  margin-right: 8px;
  margin-left: 16px;
`;
const Edit = styled(Box)`
  padding: 4px;
  background: #112d75 0% 0% no-repeat padding-box;
  width: 40px;
  border-radius: 50%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  margin-right: 16px;
`;
const Header = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  padding-right: 8px;
`;
export const Navbar = ({ refreshNavbar, pageHeader }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [differentOrganization, setDifferentOrganization] = useState(false);
  const {
    isLoggedIn,
    selectHotel,
    hotelSelect,
    hotels,
    logout,
    permission,
    token,
    authFetch,
  } = useAuth();
  const hotelId = hotelSelect?.id || null;
  let listOfOrg = null,
    userRightsView = null,
    dailyLogs = null,
    monthlyLogs = null,
    editPermission = null;
  let broadcastMessage = null;

  useEffect(() => {
    if (!token) {
      return;
    }
    if (token && hotels) {
      hotels.forEach((_, index) => {
        if (
          hotels[index + 1] &&
          hotels[index].organizationId !== hotels[index + 1].organizationId
        ) {
          setDifferentOrganization(true);
        } else {
          setDifferentOrganization(false);
        }
      });
    }
  }, [token, hotels]);
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
      if (permission[key].name === "userRightsView") {
        userRightsView = permission[key];
      }
      if (permission[key].name === "monthlyLogs") {
        monthlyLogs = permission[key];
      }
      if (permission[key].name === "dailyLogs") {
        dailyLogs = permission[key];
      }
      if (permission[key].name === "editPermission") {
        editPermission = permission[key];
      }
      if (permission[key].name === "broadcastMessage") {
        broadcastMessage = permission[key];
      }
    }
  }
  const handleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  });

  const handleChange = (event, { name: hotelname }) => {
    const foundHotel = hotels.find((hotel) => hotel.name === hotelname);
    if (foundHotel) {
      selectHotel(foundHotel);
    } else {
      selectHotel(hotelSelect);
      window.location.reload();
      return;
    }
    const orgid = hotels.find((hotel) => hotel.name === hotelname)
      .organizationId;
    if (orgid) {
      localStorage.setItem("organizationId", JSON.stringify());
    }
    window.location.reload();
  };
  const logoutApi = useCallback(async () => {
    try {
      const { del } = await authFetch({
        path: `/logout`,
      });

      await del();
    } catch (err) {
      console.log(err);
    }
  }, [authFetch]);
  const logoutHandler = () => {
    logout();
    logoutApi();
  };
  const defaultHotelProps = {
    options: [
      ...hotels
        ?.map((hotel) => hotel)
        .sort((a, b) => a.name.localeCompare(b.name)),
    ],
    getOptionLabel: (option) => option.name ?? option,
    getOptionKey: (option) => option.id,
  };
  const getAllHotels = async () => {
    const { get } = await authFetch({
      path: `/hotel`,
    });
    const { data } = await get();
    if (data) {
      localStorage.setItem("hotels", JSON.stringify(data));
    }
  };

  const sortHotels = useCallback((a, b, inputVal) => {
    if (!a || !b || !inputVal) return 0;
    const pos1 = a?.name.toLowerCase().indexOf(inputVal.toLowerCase());
    const pos2 = b?.name.toLowerCase().indexOf(inputVal.toLowerCase());

    if (pos1 < pos2) return -1;
    else if (pos1 > pos2) return 1;
    else a.name.localeCompare(b.name);
  }, []);
  const searchedHotels = useCallback(
    (options, inputValue) => {
      const filteredHotels = options.filter((option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      return filteredHotels.sort((a, b) => sortHotels(a, b, inputValue));
    },
    [sortHotels]
  );

  return (
    <>
      {isLoggedIn ? (
        <>
          <Nav>
            <Stack width={"100%"} direction={"row"} alignItems={"center"}>
              {hotelSelect &&
              window.location.pathname.split("/")[1] === "hotel" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LogoContainer toggle={isSidebarOpen}>
                    <Logo>
                      <img
                        src="/assets/logo-white.svg"
                        alt="Logo"
                        width="18px"
                        height="24px"
                      />
                    </Logo>
                  </LogoContainer>
                  <Header>{pageHeader}</Header>
                </div>
              ) : listOfOrg ? (
                <NavLink
                  to="/managing_organization"
                  style={{ textDecoration: "none" }}
                  onClick={getAllHotels}
                >
                  <LogoContainer toggle={isSidebarOpen}>
                    <Logo>
                      <img
                        src="/assets/logo-white.svg"
                        alt="Logo"
                        width="18px"
                        height="24px"
                      />
                    </Logo>
                    <Title>Precium</Title>
                  </LogoContainer>
                </NavLink>
              ) : (
                <NavLink
                  to="/choose_hotel"
                  style={{ textDecoration: "none" }}
                  onClick={getAllHotels}
                >
                  <LogoContainer toggle={isSidebarOpen}>
                    <Logo>
                      <img
                        src="/assets/logo-white.svg"
                        alt="Logo"
                        width="18px"
                        height="24px"
                      />
                    </Logo>
                    <Title>Precium</Title>
                  </LogoContainer>
                </NavLink>
              )}
              <Container
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  marginRight: "10px",
                }}
              >
                <Stack direction={"row"}>
                  {broadcastMessage &&
                    window.location.pathname.split("/")[1] === "hotel" && (
                      <UserIcon
                        sx={{
                          marginLeft: "4px",
                          marginRight: "8px",
                          padding: "5px 6px",
                        }}
                      >
                        <Link
                          exact="true"
                          to={`/hotel/${hotelId}/broadcast-messages`}
                        >
                          {" "}
                          <Forum sx={{ color: "white", mt: "2px" }} />
                        </Link>
                      </UserIcon>
                    )}
                  {(dailyLogs || monthlyLogs) &&
                  hotelSelect &&
                  window.location.pathname.split("/")[1] === "hotel" ? (
                    <UserIcon
                      sx={{
                        marginLeft: "8px",
                      }}
                    >
                      <Link
                        style={{ display: "flex", justifyContent: "center" }}
                        exact="true"
                        to={`/hotel/${hotelId}/data_upload_information`}
                      >
                        <List style={{ color: "white" }} />
                      </Link>
                    </UserIcon>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[1] === "hotel" ? (
                    <Edit
                      sx={{
                        ml: "8px",
                      }}
                    >
                      <Link
                        style={{ display: "flex", justifyContent: "center" }}
                        exact="true"
                        to={`/hotel/${hotelId}/update-profile`}
                      >
                        <ED style={{ color: "white" }} />
                      </Link>
                    </Edit>
                  ) : (
                    ""
                  )}
                  {hotelSelect &&
                  window.location.pathname.split("/")[1] === "hotel" &&
                  window.location.pathname.split("/")[3] !== "users" ? (
                    <HotelName>
                      <Autocomplete
                        {...defaultHotelProps}
                        value={hotelSelect.name.trim()}
                        freeSolo={true}
                        disableClearable
                        onChange={(event, newValue) => {
                          handleChange(event.target, newValue);
                        }}
                        filterOptions={(option, { inputValue }) => {
                          return searchedHotels(option, inputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            minWidth="1000px"
                            {...params}
                            variant="standard"
                            placeholder="Search Hotel"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </HotelName>
                  ) : (
                    <div />
                  )}
                  {userRightsView && hotelSelect && editPermission && (
                    <UserIcon>
                      <Link
                        exact="true"
                        to={`/hotel/${hotelId}/role-management`}
                      >
                        {" "}
                        <ManageAccountsIcon sx={{ color: "white" }} />
                      </Link>
                    </UserIcon>
                  )}
                  {userRightsView &&
                    hotelSelect &&
                    window.location.pathname.split("/")[1] === "hotel" && (
                      <UserIcon>
                        <Link exact="true" to={`/hotel/${hotelId}/users`}>
                          {" "}
                          <People style={{ color: "white" }} />
                        </Link>
                      </UserIcon>
                    )}{" "}
                  <LogoutIconNew>
                    <img
                      src="/assets/NewLogoutIcon.svg"
                      alt="logout icon"
                      onClick={logoutHandler}
                      style={{
                        color: "white",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </LogoutIconNew>
                </Stack>
              </Container>
            </Stack>
          </Nav>
          {window.location.pathname.split("/")[1] === "hotel" && (
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              handleSidebar={handleSidebar}
            />
          )}
        </>
      ) : (
        <div />
      )}
    </>
  );
};
