import {
  Box,
  Button,
  Table,
  TableCell,
  Typography,
  styled,
} from "@mui/material";

export const ColorContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginTop: "16px",
  background: "#fff",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  padding: "16px 16px 16px 24px",
}));

export const Heading = styled(Typography)(({ theme }) => ({
  letterSpacing: "0px",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#163A90",
}));

export const TableWrapper = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  marginTop: "16px",
  overflowY: "auto",
  width: "100%",
}));
export const KeyButton = styled(Button)((theme) => ({
  backgroundColor: "#306FBC",
  color: "#fff",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "capitalize",
  borderRadius: "8px",
  padding: "6px 10px",
  fontFamily: "Roboto",
  "&:hover": {
    backgroundColor: "#163A90",
  },
  "&:disabled": {
    cursor: "auto",
    backgroundColor: "#adaaaa",
  },
}));
export const ColorText = styled(Typography)((theme) => ({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Roboto",
  color: "#000",
}));
export const SaveButton = styled(Button)((theme) => ({
  backgroundColor: "#306FBC",
  color: "#fff",
  fontSize: "14px",
  fontWeight: 400,
  textTransform: "capitalize",
  borderRadius: "20px",

  fontFamily: "Roboto",
  "&:hover": {
    backgroundColor: "#306FBC",
  },
  "&:disabled": {
    cursor: "auto",
    backgroundColor: "#adaaaa",
  },
}));
export const TB = styled(Table)`
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    font: normal normal bold 14px/20px Roboto;
    color: #281e53;
    background: #eaecf0;
    border: none;
    padding: 12px 0px;
    text-align: center;
  }
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 14px/20px Roboto;
    color: #333333;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 32px;
    padding: 8px 15px;
    text-align: center;
  }
`;
