import {
  Box,
  Button,
  Stack,
  Table,
  TableCell,
  TextField,
  Typography,
  styled,
} from "@mui/material";

export const P = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  margin-left: 10px;
`;
export const SubText = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 10px;
`;
export const HeaderCard = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #281e53;
`;
export const LabelNew = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;export const TableWrapper = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  marginTop: "16px",
  height: "65vh",
  overflowY: "auto",
}));
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;
export const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  position: "relative",
  boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

export const TableWrapper = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  marginTop: "16px",
  height: "65vh",
  overflowY: "auto",
}));
export const KeyButton = styled(Button)((theme) => ({
  backgroundColor: "#306FBC",
  color: "#fff",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "capitalize",
  borderRadius: "30px",
  padding: "8px 16px",
  fontFamily: "Roboto",
  "&:hover": {
    backgroundColor: "#163A90",
  },
  "&:disabled": {
    cursor: "auto",
    backgroundColor: "#adaaaa",
  },
}));

export const TB = styled(Table)`
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    font: normal normal bold 14px/20px Roboto;
    color: #281e53;
    background: #eaecf0;
    border: none;
    padding: 12px 0px;
    text-align: center;
  }
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 14px/20px Roboto;
    color: #333333;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 32px;
    padding: 8px 15px;
    text-align: center;
  }
`;
