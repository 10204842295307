import { Input as Inpt } from "@mui/material";
import { CalendarToday, FileUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { styled } from "@mui/system";
import { GhostButton, Nodata } from "../../sdk";
import useForecastSheetUpload from "../hooks/useForecastSheetUpload";
import { ModelBody } from "./ForecastSheetUploadModal";
import { ModelBody as MB } from "./LastNightActualsModal";
import useLastNightActualModalUpload from "../hooks/useLastNightActualModalUpload";

export const PageTitle = styled(Box)`
  font: normal normal bold 18px/22px Roboto;
  letter-spacing: 0px;
  margin-right: 0px;
`;

const MonthPickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 22px/28px Roboto;
`;

export const ForecastPage = styled(Box)`
  padding: 60px 30px 0px 100px;
  width: 100%;
`;

const Input = styled(Inpt)`
  input.MuiInputBase-input {
    padding: 0;
    font: 410 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000;
    text-align: center;
    font: normal normal normal 15px/20px Roboto;

    border: none;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &&&.MuiInput-underline::before {
    border-bottom: none;
  }
  &&&.MuiInput-underline::after {
    border-bottom: none;
  }
`;

const StyledForecastPage = styled(ForecastPage)(({ theme }) => ({
  ".sticky": {
    position: "sticky",
    left: 0,
    top: 0,
    background: "#fff",
    zIndex: "1",
  },
  ".stickyRight": {
    position: "sticky",
    left: "120px",
    background: "#fff",
    zIndex: "1",
    top: "0",
  },
  ".icon": {
    fill: "white",
  },

  ".input": {
    font: "normal normal bold 16px / 20px Roboto",
    fontWeight: "600",
    color: "#000",
    margin: 0,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const isAllData = (data) => !data?.every(curr => curr?.noOfRoomsBooked === -1)

const isAllDateWiseData = (data, index) =>
  !data?.every(curr => curr?.arrBudget[index]?.noOfRoomsBooked === -1)

const isAllMonthWiseData = (data) => !data?.every(curr => curr?.arrBudget?.every(budget => budget?.noOfRoomsBooked === -1))

export const ForecastSheetUpload = ({ setPageHeader }) => {
  const {
    maximumDate,
    defaultValue,
    selectedDateNew,
    setSelectedDateNew,
    formatInputValue,
    uploadDateData,
    hasDateData,
    timeOption,
    handleTimeOption,
    hasTableData,
    tableData,
    fileHandler,
    setUploadActualDate,
    uploadActualDate,
    fileGetter,
    setOpenSelectBar,
    removeFile,
    filename,
    handleToggle,
    toggle,
    setToggle,
    openSelectbar,
    open,
    networkMsg,
    handleClose,
  } = useForecastSheetUpload();

  const {
    comparitiveDate,
    setComparitiveDate,
    setOpenn,
    networkMsgg,
    setNetworkMsgg,
    openn,
    lastfileHandler,
    lastfileGetter,
    openLastSelectbar,
    setOpenLastSelectBar,
    removeLastFile,
    lastfilename,
    handleLastToggle,
    toggleLast,
    setToggleLast,
  } = useLastNightActualModalUpload();

  const [dataSetValue, setDataSetvalue] = useState(0);

  useEffect(() => {
    setPageHeader("Forecast Sheet Upload");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "110px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  return (
    <StyledForecastPage style={{ background: "#fff", minHeight: "100vh" }}>
      <Paper
        elevation={0}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px 0 0 0",
          marginBottom: "10px",
        }}
      >
        <Stack
          direction={"row"}
          spacing={4}
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          <Stack direction="row" spacing={2}>
            <Paper
              elevation={2}
              style={{
                height: "52px",
                padding: "0px 10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack direction={"row"} style={{}}>
                <PageTitle>
                  <CalendarToday
                    style={{
                      color: "rgba(48, 111, 188, 1)",
                      marginTop: "3px",
                    }}
                  />
                </PageTitle>
                <Box>
                  <DatePicker
                    value={selectedDateNew}
                    onChange={setSelectedDateNew}
                    renderInput={renderCustomInput}
                    calendarClassName="myCustomCalendar"
                    maximumDate={maximumDate}
                  />
                </Box>
              </Stack>
            </Paper>

            {hasDateData && (
              <Paper
                elevation={2}
                style={{
                  width: "120px",
                  marginBottom: "16px",
                  height: "52px",
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    padding: "10px",
                    alignItems: "center",
                    paddingTop: "12px",
                  }}
                  spacing={2}
                >
                  <Select
                    value={timeOption}
                    onChange={handleTimeOption}
                    size="small"
                    style={{ height: "30px", width: "100%", fontWeight: "600" }}
                    disableUnderline
                    variant="standard"
                    placeholder="Select Time"
                    inputProps={{
                      classes: {
                        input: "input",
                        icon: "icon",
                      },
                    }}
                  >
                    {uploadDateData?.map((val, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={val.id}
                          style={{ fontWeight: "600" }}
                        >
                          {val.uploadedOn.toString().slice(11, 16)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
              </Paper>
            )}
          </Stack>

          <Stack direction={"row"} gap="24px">
            <GhostButton
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "rgb(48, 111, 188)",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "0px",
                textTransform: "none",
                padding: "5px 10px",
              }}
              onClick={() => {
                setOpenLastSelectBar(true);
              }}
            >
              Upload Last Night Actuals
              <FileUpload sx={{ color: "inherit", marginLeft: "1px" }} />
            </GhostButton>
            <GhostButton
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "rgb(48, 111, 188)",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "1px",
                padding: "5px 10px",
              }}
              onClick={() => {
                setOpenSelectBar(true);
                setUploadActualDate(new Date());
                setToggle(false);
              }}
            >
              Upload
              <FileUpload sx={{ color: "inherit", marginLeft: "1px" }} />
            </GhostButton>
          </Stack>
        </Stack>
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        {open && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsg === "Upload Successful" ? "#228b22" : "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openn}
        autoHideDuration={3000}
        onClose={() => setOpenn(false)}
      >
        {openn && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsgg === "File Uploaded Successfully"
                  ? "#228b22"
                  : "#CA3433",
            }}
            message={networkMsgg}
          />
        )}
      </Snackbar>

      {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarColor ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {networkMsg}
        </Alert>
      </Snackbar> */}

      <Modal
        open={openLastSelectbar}
        onClose={() => setOpenLastSelectBar(false)}
      >
        <div>
          <MB
            comparitiveDate={comparitiveDate}
            setComparitiveDate={setComparitiveDate}
            lastfileHandler={lastfileHandler}
            lastfileGetter={lastfileGetter}
            setOpenLastSelectBar={setOpenLastSelectBar}
            removeLastFile={removeLastFile}
            lastfilename={lastfilename}
            handleLastToggle={handleLastToggle}
            toggleLast={toggleLast}
          />
        </div>
      </Modal>

      <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
        <div>
          <ModelBody
            fileHandler={fileHandler}
            setUploadActualDate={setUploadActualDate}
            uploadActualDate={uploadActualDate}
            fileGetter={fileGetter}
            setOpenSelectBar={setOpenSelectBar}
            removeFile={removeFile}
            filename={filename}
            handleToggle={handleToggle}
            toggle={toggle}
          />
        </div>
      </Modal>

      <Stack
        direction={"row"}
        spacing={2}
        style={{
          margin: "5px 0  15px 0",
        }}
      >
        {hasTableData &&
          tableData?.map((monthValue, index) => {
            return (
              <Paper
                key={index}
                elevation={2}
                style={{
                  height: "40px",
                }}
              >
                <Button
                  onClick={() => {
                    setDataSetvalue(index);
                  }}
                >
                  {monthValue?.month}
                </Button>
              </Paper>
            );
          })}
      </Stack>

      {hasTableData ? (
        <Paper elevation={0}>
          <TableContainer
            style={{
              maxHeight: "calc(100vh - 300px)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ color: "#fff" }}>
                  <TableCell
                    className="sticky"
                    style={{
                      background: "rgb(48,111,188)",
                      color: "#fff",
                      zIndex: "9",
                    }}
                  >
                    Market Segment
                  </TableCell>
                  <TableCell
                    className="stickyRight"
                    style={{
                      background: "rgb(48,111,188)",
                      color: "#fff",
                      zIndex: "9",
                    }}
                  >
                    Performance Analyzer
                  </TableCell>
                  {tableData[dataSetValue]?.marketSegment[0]?.arrBudget.map(
                    (val, index) => {
                      return (
                        <TableCell
                          style={{
                            background: "rgb(48,111,188)",
                            color: "#fff",
                          }}
                        >
                          <Stack direction={"column"} spacing={2}>
                            <Typography
                              style={{ fontSize: "14px", padding: "0 2px" }}
                            >
                              {new Date(val.date).toDateString().slice(4, 10)}
                            </Typography>
                          </Stack>
                        </TableCell>
                      );
                    }
                  )}
                  <TableCell
                    style={{
                      background: "rgb(48,111,188)",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData[dataSetValue]?.marketSegment?.map((val, index) => {
                  return (
                    <>
                      <TableRow
                        style={{
                          background:
                            index % 2 === 0 ? "#fff" : "rgb(214,226,242)",
                        }}
                      >
                        <TableCell
                          className="sticky"
                          style={{
                            minWidth: "120px",
                            borderRight: "1px solid rgb(224,224,224)",
                            borderLeft: "1px solid rgb(224,224,224)",
                          }}
                        >
                          {val.segmentName}
                        </TableCell>
                        <TableCell
                          className="stickyRight"
                          style={{
                            minWidth: "120px",
                            borderRight: "1px solid rgb(224,224,224)",
                            padding: "16px 5px",
                          }}
                        >
                          <Stack direction={"column"} spacing={1.5}>
                            <Typography>Room Nights</Typography>
                            <Typography>ADR</Typography>
                            <Typography>Revenue</Typography>
                          </Stack>
                        </TableCell>
                        {val.arrBudget.map((data, k) => {
                          return (
                            <TableCell style={{ padding: "16px 10px" }}>
                              <Stack direction={"column"} spacing={2}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    padding: "0 2px",
                                  }}
                                >
                                  {data?.noOfRoomsBooked !== -1
                                    ? data?.noOfRoomsBooked.toFixed(0)
                                    : "-"}
                                </Typography>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    padding: "0 2px",
                                  }}
                                >
                                  {data?.averageRoomRate !== -1
                                    ? data?.averageRoomRate.toFixed(2)
                                    : "-"}
                                </Typography>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    padding: "0 2px",
                                  }}
                                >
                                  {data?.noOfRoomsBooked !== -1
                                    ? Math.floor(
                                        data?.noOfRoomsBooked *
                                          data?.averageRoomRate *
                                          100
                                      ) / 100
                                    : "-"}
                                </Typography>
                              </Stack>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          style={{
                            borderLeft: "1px solid rgb(224,224,224)",
                            textAlign: "center",
                            padding: "16px 10px",
                          }}
                        >
                          <Stack direction={"column"} spacing={2}>
                            <Typography style={{ fontSize: "14px" }}>
                              {isAllData(
                                tableData[dataSetValue]?.marketSegment[index]
                                  ?.arrBudget
                              )
                                ? tableData[dataSetValue]?.marketSegment[
                                    index
                                  ]?.arrBudget
                                    .reduce(
                                      (prev, next) =>
                                        prev +
                                        Math.max(next.noOfRoomsBooked, 0),
                                      0
                                    )
                                    .toFixed(0)
                                : "-"}
                            </Typography>
                            <Typography style={{ fontSize: "14px" }}>
                              {/* {tableData[dataSetValue]?.marketSegment[
                                index
                              ]?.arrBudget
                                .reduce(
                                  (prev, next) => prev + next.averageRoomRate,
                                  0
                                )
                                .toFixed(2)} */}

                              {isAllData(
                                tableData[dataSetValue]?.marketSegment[index]
                                  ?.arrBudget
                              )
                                ? tableData[dataSetValue]?.marketSegment[
                                    index
                                  ]?.arrBudget
                                    .reduce(
                                      (prev, next) =>
                                        prev +
                                        Math.max(next.averageRoomRate, 0),
                                      0
                                    )
                                    .toFixed(2)
                                : "-"}
                            </Typography>
                            <Typography style={{ fontSize: "14px" }}>
                              {isAllData(
                                tableData[dataSetValue]?.marketSegment[index]
                                  ?.arrBudget
                              ) ? (
                                tableData[dataSetValue]?.marketSegment[
                                  index
                                ]?.arrBudget
                                  .reduce(
                                    (prev, next) =>
                                      prev + Math.max(next.noOfRoomsBooked, 0),
                                    0
                                  )
                                  .toFixed(0) *
                                  tableData[dataSetValue]?.marketSegment[
                                    index
                                  ]?.arrBudget
                                    .reduce(
                                      (prev, next) =>
                                        prev +
                                        Math.max(next.averageRoomRate, 0),
                                      0
                                    )
                                    .toFixed(2)
                              ).toFixed(2) : '-'}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
                <TableRow>
                  <TableCell
                    className="sticky"
                    style={{
                      zIndex: "9",
                      borderRight: "1px solid rgb(224,224,224)",
                      borderLeft: "1px solid rgb(224,224,224)",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className="stickyRight"
                    style={{
                      zIndex: "9",
                      borderRight: "1px solid rgb(224,224,224)",
                      padding: "16px 5px",
                    }}
                  >
                    <Stack direction={"column"} spacing={1.5}>
                      <Typography>Room Nights</Typography>
                      <Typography>ADR</Typography>
                      <Typography>Revenue</Typography>
                    </Stack>
                  </TableCell>
                  {tableData[dataSetValue]?.marketSegment[0]?.arrBudget.map(
                    (val, index) => {
                      return (
                        <TableCell style={{ padding: "16px 10px" }}>
                          <Stack direction={"column"} spacing={2}>
                            <Typography
                              style={{ textAlign: "center", fontSize: "14px" }}
                            >
                              {isAllDateWiseData(tableData[dataSetValue]?.marketSegment, index) ?
                                tableData[dataSetValue]?.marketSegment
                                .reduce(
                                  (prev, next) =>
                                    prev +
                                    Math.max(
                                      next?.arrBudget[index]?.noOfRoomsBooked,
                                      0
                                    ),
                                  0
                                )
                                .toFixed(0) : '-'}
                            </Typography>
                            <Typography
                              style={{ textAlign: "center", fontSize: "14px" }}
                            >
                              {isAllDateWiseData(tableData[dataSetValue]?.marketSegment, index) ?
                                tableData[dataSetValue]?.marketSegment
                                .reduce(
                                  (prev, next) =>
                                    prev +
                                    Math.max(
                                      next?.arrBudget[index]?.averageRoomRate,
                                      0
                                    ),
                                  0
                                )
                                .toFixed(2) : '-'}
                            </Typography>
                            <Typography
                              style={{ textAlign: "center", fontSize: "14px" }}
                            >
                              {isAllDateWiseData(tableData[dataSetValue]?.marketSegment, index) ? (
                                tableData[dataSetValue]?.marketSegment
                                  .reduce(
                                    (prev, next) =>
                                      prev +
                                      Math.max(
                                        next?.arrBudget[index]?.noOfRoomsBooked,
                                        0
                                      ),
                                    0
                                  )
                                  .toFixed(0) *
                                tableData[dataSetValue]?.marketSegment
                                  .reduce(
                                    (prev, next) =>
                                      prev +
                                      next?.arrBudget[index]?.averageRoomRate,
                                    0
                                  )
                                  .toFixed(2)
                              ).toFixed(2):'-'}
                            </Typography>
                          </Stack>
                        </TableCell>
                      );
                    }
                  )}
                  <TableCell
                    style={{
                      borderLeft: "1px solid rgb(224,224,224)",
                      textAlign: "center",
                      padding: "16px 10px",
                      minWidth: "120px",
                    }}
                  >
                    <Stack direction={"column"} spacing={2}>
                      <Typography
                        style={{ textAlign: "center", fontSize: "14px" }}
                      >
                        {isAllMonthWiseData(tableData[dataSetValue]?.marketSegment) ? tableData[dataSetValue]?.marketSegment
                          ?.reduce(
                            (prev, next) =>
                              prev +
                              next.arrBudget?.reduce(
                                (prev, next) =>
                                  prev + Math.max(next.noOfRoomsBooked, 0),
                                0
                              ),
                            0
                          )
                          .toFixed(0) : '-'}
                      </Typography>
                      <Typography
                        style={{ textAlign: "center", fontSize: "14px" }}
                      >
                        {isAllMonthWiseData(tableData[dataSetValue]?.marketSegment) ? tableData[dataSetValue]?.marketSegment
                          ?.reduce(
                            (prev, next) =>
                              prev +
                              next.arrBudget?.reduce(
                                (prev, next) =>
                                  prev + Math.max(next.averageRoomRate, 0),
                                0
                              ),
                            0
                          )
                          .toFixed(2) : '-'}
                      </Typography>
                      <Typography
                        style={{ textAlign: "center", fontSize: "14px" }}
                      >
                        {isAllMonthWiseData(tableData[dataSetValue]?.marketSegment)? (
                          tableData[dataSetValue]?.marketSegment
                            ?.reduce(
                              (prev, next) =>
                                prev +
                                next.arrBudget?.reduce(
                                  (prev, next) =>
                                    prev + Math.max(next.averageRoomRate, 0),
                                  0
                                ),
                              0
                            )
                            .toFixed(2) *
                          tableData[dataSetValue]?.marketSegment
                            ?.reduce(
                              (prev, next) =>
                                prev +
                                next.arrBudget?.reduce(
                                  (prev, next) =>
                                    prev + Math.max(next.noOfRoomsBooked, 0),
                                  0
                                ),
                              0
                            )
                            .toFixed(0)
                        ).toFixed(2): '-'}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Nodata />
      )}
    </StyledForecastPage>
  );
};
