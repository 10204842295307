import React, { useState } from "react";
import { TB, TableWrapper, Td, Th } from "./styles";
import {
  Box,
  Checkbox,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { LocationOn, ReportProblem } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default function EventDetailTable({
  eventDetails,
  count,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  selectedRows,
  setSelectedRows,
  permission,
}) {
  const history = useHistory();
  const handleCheckboxChange = (eventId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row === eventId);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row !== eventId);
      } else {
        return [...prevSelectedRows, eventId];
      }
    });
  };
  const selected = eventDetails?.event_details?.events?.filter(
    (item) => !item.isSelected
  );
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allEvents = selected?.map((event) => event.id);
      setSelectedRows(allEvents);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <Box>
      <TableWrapper>
        <TB
          style={{
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <TableHead
            sx={{
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <TableRow>
              <Th
                style={{
                  borderRadius: "8px 0px 0px 0px",
                }}
              >
                Event Name
              </Th>
              <Th>Event Address</Th>
              <Th>Event Date</Th>
              <Th
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "0px 8px 0px 0px",
                  padding: selected?.length === 0 ? "12px 0px" : "3px 0px",
                }}
              >
                <span>Select </span>{" "}
                {selected?.length !== 0 ? (
                  <Checkbox
                    size="small"
                    disabled={!permission}
                    checked={selectedRows.length === selected?.length}
                    onChange={handleSelectAllChange}
                  />
                ) : null}
              </Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventDetails?.event_details?.events?.length === 0 && (
              <TableRow
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={"200px"}
              >
                <TableCell
                  colSpan={12}
                  sx={{
                    textAlign: "center",
                    border: "0px",
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="100%"
                  >
                    <Typography ml={1}>No Events Found</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {eventDetails?.event_details?.events.length > 0 &&
              eventDetails?.event_details?.events?.map((event) => (
                <TableRow key={event.id}>
                  <Td
                    sx={{
                      maxWidth: "150px",
                    }}
                  >
                    {event?.eventname}
                  </Td>
                  <Td
                    sx={{
                      maxWidth: "150px",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={event.address ? "start" : "center"}
                      width={"100%"}
                      gap={1}
                    >
                      {event?.latitude !== "None" &&
                        event?.longitude !== "None" && (
                          <Link
                            href={`https://www.google.com/maps/search/?api=1&query=${event?.latitude}%2C${event.longitude}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LocationOn
                              sx={{
                                color: "#163A90",
                              }}
                            />
                          </Link>
                        )}

                      <Typography textAlign={event.address ? "left" : "center"}>
                        {event.address ? event.address : "N/A"}
                      </Typography>
                    </Box>
                  </Td>
                  <Td>{formatDate(event.startdate)}</Td>
                  <Td>
                    {event?.isSelected ? (
                      <Checkbox
                        size="small"
                        disabled={event.isSelected}
                        checked={true}
                        onChange={() =>
                          handleCheckboxChange(event.id, event.startdate)
                        }
                      />
                    ) : (
                      <Checkbox
                        size="small"
                        disabled={!permission}
                        checked={selectedRows.some((row) => row === event.id)}
                        onChange={() => handleCheckboxChange(event.id)}
                      />
                    )}
                  </Td>
                </TableRow>
              ))}
          </TableBody>
        </TB>
      </TableWrapper>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // labelDisplayedRows={({ from, to }) => null}
        // nextIconButtonProps={{
        //   disabled: eventDetails?.event_details?.events.length < rowsPerPage,
        // }}
      />
    </Box>
  );
}
