import Autocomplete from "@mui/material/Autocomplete";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk";
import { styled } from "@mui/system";
import {
  Cross,
  Done,
  Forecast,
  Head,
  InputField,
  Label,
  Section,
  TextFieldBig,
  UploadSection,
} from "../Styles";
function getModalStyle() {
  const top = 9;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}, -${left})`,
  };
}
const StyledSection = styled(Section)(({ theme }) => ({
  position: "absolute",
  width: 400,
  height: "fit-content",
  backgroundColor: "#fff",
  boxShadow: 5,
}));
export function ModelBody({
  fileId,
  setOpenSelectBar,
  contactDetails,
  emailid,
  setnetworkMsg,
  setOpen,
}) {
  const [modalStyle] = useState(getModalStyle);
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const [email, setEmail] = useState([]);
  const [pickup, setPickup] = useState(1);

  function handlePickup(val) {
    val = val < 0 ? val * -1 : val === "0" ? "" : val;
    setPickup(val);
  }
  const defaultProps = {
    options: [...contactDetails.map((detail) => detail.email), emailid],
    getOptionLabel: (option) => option,
  };
  async function submitEmail() {
    if (email.length === 0) {
      setnetworkMsg("Enter a email");
      setOpen(true);
      return;
    }
    setOpenSelectBar(false);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/email-excel/${fileId}`,
    });
    const { data, error, response } = await post({
      emailList: email.map((mail) => mail.trim()),
      pickup: parseInt(pickup),
    });
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Something Went Wrong");
      setOpen(true);
    } else if (!!data && data?.message === "Email Sent Successfully") {
      setnetworkMsg("Email Sent Successfully");
      setOpen(true);
      console.log("Email sent");
    }
  }

  return (
    <StyledSection style={modalStyle}>
      <Head>
        <Forecast>Send Email</Forecast>
        <Cross onClick={() => setOpenSelectBar(false)}>&#x274C;</Cross>
      </Head>
      <UploadSection>
        <InputField>
          <Label htmlFor="picker">Email</Label>

          <Autocomplete
            multiple
            {...defaultProps}
            value={email}
            style={{ width: "245px" }}
            freeSolo={true}
            autoSelect
            onChange={(event, newValue) => {
              setEmail(newValue);
            }}
            renderInput={(params) => (
              <TextFieldBig {...params} style={{ width: "245px" }} />
            )}
          />
        </InputField>

        <InputField>
          <Label>Pickup</Label>
          <TextFieldBig
            type="number"
            variant="standard"
            value={pickup}
            InputProps={{ inputProps: { min: 1, max: 14 } }}
            onChange={(e) => handlePickup(e.target.value)}
          />
        </InputField>
      </UploadSection>

      <Done next onClick={submitEmail} type="button">
        Send
      </Done>
    </StyledSection>
  );
}
