import { TableContainer } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { styled } from "@mui/system";
import { getDateDDMM, getDateDDMMYYYY, getDay, getHrMin } from "../../sdk";
import {
  Box,
  DATE,
  DAY,
  MyCard,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../Styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".root": {
    width: "100%",
  },
  ".stickyHeader": {
    position: "sticky",
    top: 0,
  },
  ".container": {
    height: "calc(100vh - 190px)",
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",
      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      style={{ width: "100%", height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ width: "100%", padding: "0 8px", height: "100%" }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
// just checking
const PricingTable = ({
  array,
  fileId,
  allFilesForDay,
  uploadImage,
  sendRemark,
  selectedImages,
  setSelectedImages,
  remarkData,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [popOver, setPopOver] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverDate, setPopOverDate] = useState("");
  const [disablePrevDate, setDisablePrevDate] = useState(false);
  const [futureRemark, setFutureRemark] = useState(false);

  const id = !!anchorEl ? "simple-popover" : undefined;

  let previousLength = 0;

  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    previousLength = newLength;
  };

  const convertImageToBlob = async (image) => {
    const response = await fetch(image.uri);
    const blob = await response.blob();
    return blob;
  };

  const handleImagePreview = async (e) => {
    if (e.target.files) {
      const images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (file.type.startsWith("image")) {
          const image = URL.createObjectURL(file);
          const blob = await convertImageToBlob({ uri: image });
          images.push(blob);
        } else {
          alert("Only image files are allowed.");
          break;
        }
      }
      const imageUrl = await uploadImage(images);
      setSelectedImages((prevState) => [...prevState, ...imageUrl]);
      setPopOver((prevState) => ({
        ...prevState,
        images: [...prevState?.images, ...imageUrl],
      }));
    }
  };

  const handleDeleteImage = (index, deletedImage) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((img, i) => i !== index)
    );
    setPopOver((prevState) => ({
      ...prevState,
      images: prevState.images?.filter(
        (item) => item.imageURL !== deletedImage.imageURL
      ),
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedImages([]);
  };

  const handleSaveChanges = () => {
    setIsEditing(false);
    sendRemark(popOverDate, popOver);
    setAnchorEl(null);
  };

  const handleEditBtn = () => {
    setIsEditing(true);
  };

  const handleDiscard = () => {
    setIsEditing(false);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item.remark.date).toLocaleDateString()) ===
        String(new Date(popOverDate).toLocaleDateString())
      );
    });
    setPopOver(data);
    setSelectedImages([]);
  };

  const handleRowHover = (date, e) => {
    setPopOverDate(date);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item.remark.date).toLocaleDateString()) ===
        String(new Date(date).toLocaleDateString())
      );
    });
    if (data) {
      setPopOver(data);
      setNoData(false);
    } else {
      setPopOver(undefined);
      setNoData(true);
    }
    setAnchorEl(e.currentTarget);
    setDisablePrevDate(
      String(new Date().toLocaleDateString()) ===
        String(new Date(date).toLocaleDateString())
        ? false
        : new Date() > new Date(date)
    );
    setFutureRemark(new Date() < new Date(date));
  };

  return (
    <ClassWrapper>
      {allFilesForDay.map((file, index) => (
        <TabPanel value={fileId} index={file.id} key={index}>
          {array && array[0] ? (
            <MyCard>
              <TableContainer className="container">
                <Table aria-label="sticky table">
                  <Thead>
                    <Tr className="stickyHeader">
                      <Th
                        style={{
                          width: "80px",
                          background: " #306FBC",
                          color: "#ffffff",
                          borderRadius: "8px 0px 0px 0px",
                          verticalAlign: "middle",
                        }}
                      >
                        Date
                      </Th>
                      <Th
                        style={{
                          width: "80px",
                          background: " #306FBC",
                          color: "#ffffff",
                          verticalAlign: "middle",
                        }}
                      >
                        Occ%
                      </Th>
                      <Th
                        style={{
                          width: "150px",
                          background: " #306FBC",
                          color: "#ffffff",
                          verticalAlign: "middle",
                        }}
                      >
                        Our Price
                      </Th>
                      {array &&
                        array[0].competitorPrices &&
                        array[0].competitorPrices
                          .sort((a, b) => a.competitorID - b.competitorID)
                          .map((datax) => {
                            return (
                              <Th
                                style={{
                                  width: "150px",
                                  background: " #306FBC",
                                  color: "#ffffff",

                                  borderRadius: "0px",
                                  verticalAlign: "middle",
                                }}
                                key={datax.competitorID}
                              >
                                {datax.competitorName}
                              </Th>
                            );
                          })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {array &&
                      array.map((data, index) => (
                        <Tr key={index}>
                          <Td
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontweight: 500,
                              fontSize: "16px",
                              lineHeight: "20px",

                              color: "#212121",
                              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                          >
                            <DATE>
                              <div
                                onClick={(e) => {
                                  handleRowHover(data.date, e);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {getDateDDMM(data.date)}{" "}
                              </div>
                              <DAY>{getDay(data.date)}</DAY>
                            </DATE>
                          </Td>
                          <Td
                            style={
                              data?.occupancyColor
                                ? {
                                    background: `linear-gradient(${data.occupancyColor}, #FFFFFF)`,
                                    borderRight: "solid 1px #FFFFFF",
                                    textAlign: "center",
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "#212121",
                                  }
                                : {}
                            }
                          >
                            {data.occupancy === 0 && !data.occupancyColor
                              ? "-"
                              : Math.round(data.occupancy) + "%"}
                          </Td>
                          <Td
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontweight: 500,
                              fontSize: "16px",
                              lineHeight: "20px",

                              color: "#212121",
                            }}
                          >
                            {data?.isSoldOut ? (
                              <p>Sold Out</p>
                            ) : data?.Price === -2 ? (
                              <p>-</p>
                            ) : (
                              data?.Price
                            )}
                          </Td>

                          {data.competitorPrices &&
                            data.competitorPrices
                              .sort((a, b) => a.competitorID - b.competitorID)
                              .map((datax, idx) =>
                                datax.price === -2 ? (
                                  <Td
                                    key={idx}
                                    style={{
                                      font:
                                        " normal normal 500 16px/20px Roboto",
                                      border: "solid 1px #FFFFFF",
                                      padding: "0px 0px",

                                      background:
                                        "linear-gradient(#757575,#FFFFFF)",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      N/A
                                    </div>
                                  </Td>
                                ) : (
                                  <Td
                                    key={idx}
                                    style={
                                      data.Price < datax.price ||
                                      datax.price === -1
                                        ? {
                                            font:
                                              " normal normal 500 16px/20px Roboto",
                                            border: "solid 1px #FFFFFF",
                                            padding: "0px 0px",

                                            background:
                                              "linear-gradient(#4ABD3C,#FFFFFF)",
                                            borderRadius: "4px",
                                          }
                                        : {
                                            font:
                                              " normal normal 500 16px/20px Roboto",
                                            border: "solid 1px #FFFFFF",
                                            padding: "0px 0px",
                                            color: "#FFF",
                                            background:
                                              "linear-gradient(#D30E08,#FFFFFF)",

                                            borderRadius: "4px",
                                          }
                                    }
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          width: "100%",
                                          background:
                                            datax.price === -1
                                              ? "linear-gradient(#757575,#FFFFFF)"
                                              : "",
                                          height: "42px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {datax?.isSoldOut ? (
                                          <p>Sold&nbsp;Out</p>
                                        ) : (
                                          datax.price
                                        )}
                                      </Box>

                                      <Box
                                        style={
                                          datax.priceDifference === -1 ||
                                          datax.priceDifference >= 0
                                            ? {
                                                background:
                                                  datax.priceDifference === -1
                                                    ? "linear-gradient(rgb(167, 166, 168),#FFFFFF)"
                                                    : data.Price <
                                                        datax.price ||
                                                      datax.price === -1
                                                    ? "linear-gradient(rgb(75,193,60,0.1), rgb(255, 255, 255,1))"
                                                    : "linear-gradient(rgb(75,193,60,0.9), rgb(255, 255, 255,1))",

                                                height: "42px",
                                                padding: "0px 0px",

                                                paddingTop: "2px",

                                                textAlign: "center",
                                                width: "100%",
                                                font:
                                                  " normal normal 500 16px Roboto",
                                                display: "flex",
                                                justifyContent: "center",
                                              }
                                            : {
                                                background: !(
                                                  data.Price < datax.price ||
                                                  datax.price === -1
                                                )
                                                  ? "linear-gradient(rgb(211,14,8,0.1), rgb(255, 255, 255,1))"
                                                  : "linear-gradient(rgb(211,14,8,0.9), rgb(255, 255, 255,1))",

                                                height: "42px",
                                                color: "#FFF",
                                                textAlign: "center",

                                                paddingTop: "2px",
                                                padding: "0px 0px",
                                                width: "100%",
                                                font:
                                                  "normal normal 500 16px Roboto",
                                                display: "flex",
                                                justifyContent: "center",
                                              }
                                        }
                                      >
                                        <Box
                                          style={{
                                            margin: "auto",
                                          }}
                                        >
                                          {datax.priceDifference === -1 ? (
                                            <p>Sold&nbsp;Out</p>
                                          ) : (
                                            datax.priceDifference
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Td>
                                )
                              )}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </MyCard>
          ) : (
            <Box></Box>
          )}
        </TabPanel>
      ))}

      <Draggable>
        {noData ? (
          disablePrevDate ? (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <Stack direction={"row"} p={2} gap={4}>
                <Typography>No Remark</Typography>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Stack>
            </Popover>
          ) : futureRemark ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <>
                <Stack direction="row" mt={3}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(popOver?.remark?.date ?? popOverDate)}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack direction="row" gap={"141px"} mt={3} ml={3} mr={3}>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    Last Edited By: {popOver?.userName}
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    Last edited Time:{" "}
                  </Typography>
                </Stack>

                <TextField
                  sx={{ ml: 3, mt: 2, mr: 3 }}
                  multiline
                  fullWidth
                  value={popOver?.remark?.remark}
                  onChange={(e) => {
                    setPopOver({
                      ...popOver,
                      remark: { ...popOver?.remark, remark: e.target.value },
                    });
                  }}
                  variant="standard"
                  placeholder="Type your remark..."
                  InputProps={{ disableUnderline: "true" }}
                  onInput={handleInput}
                />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    padding: "1px",
                  }}
                >
                  {selectedImages?.map((img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        marginLeft: "20px",
                      }}
                    >
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "7px",
                          right: "-17px",
                          color: "white",
                          backgroundColor: "#6C7270",
                          borderRadius: "50%",
                          height: "17px",
                          width: "18px",
                        }}
                        onClick={() => handleDeleteImage(index)}
                      />
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  ))}
                </div>

                <Stack direction="row" style={{ display: "flex" }} mt={7} p={2}>
                  <label>
                    <TextField
                      type="file"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ visibility: "hidden" }}
                      variant="standard"
                      onChange={handleImagePreview}
                      multiple
                    />
                    <Stack direction="row">
                      <AddPhotoAlternateIcon
                        onClick={handleImagePreview}
                        sx={{ color: "grey", cursor: "pointer" }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "grey",
                          ml: 1,
                          cursor: "pointer",
                        }}
                      >
                        Add Image
                      </Typography>
                    </Stack>
                  </label>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveChanges}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        alignSelf: "end",
                        fontSize: "16px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </>
                </Stack>
              </>
            </Popover>
          )
        ) : (
          <Popover
            id={id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPopover-paper": {
                border: "1px solid #000000",
                maxWidth: "587px",
                maxHeight: "712px",
                p: 2,
              },
            }}
          >
            <>
              <Stack direction="row" mt={1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    textAlign: "center",
                    cursor: "move",
                  }}
                >
                  Remark of {getDateDDMMYYYY(popOver?.remark?.date)}{" "}
                </Typography>
                <CloseIcon
                  sx={{ cursor: "pointer", mr: "10px" }}
                  onClick={handleClose}
                />
              </Stack>

              <Stack
                direction="row"
                mt={3}
                ml={1}
                mr={1}
                gap={"141px"}
                justifyContent={"space-between"}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                  Last Edited By: {popOver?.userName ?? ""}{" "}
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                  Last edited Time: {getHrMin(popOver?.remark?.createdAt ?? "")}{" "}
                </Typography>
              </Stack>

              <TextField
                disabled={!isEditing}
                sx={{
                  ml: 1,
                  mt: 2,
                  mr: 1,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                multiline
                fullWidth
                value={
                  popOver?.remark?.remark === "" ? " " : popOver?.remark?.remark
                }
                onChange={(e) => {
                  setIsEditing(true);
                  setPopOver({
                    ...popOver,
                    remark: { ...popOver?.remark, remark: e.target.value },
                  });
                }}
                variant="standard"
                placeholder="Type your remark..."
                InputProps={{ disableUnderline: "true" }}
                onInput={handleInput}
              />

              {isEditing ? (
                <label>
                  <TextField
                    type="file"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{ visibility: "hidden" }}
                    variant="standard"
                    onChange={handleImagePreview}
                    multiple
                  />
                  <Stack direction="row" justifyContent={"end"}>
                    <AddPhotoAlternateIcon
                      onClick={handleImagePreview}
                      sx={{ color: "grey", cursor: "pointer" }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "grey",
                        ml: 1,
                        cursor: "pointer",
                      }}
                    >
                      Add Image
                    </Typography>
                  </Stack>
                </label>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  padding: "1px",
                }}
              >
                {[...(!!popOver?.images?.length ? popOver?.images : [])].map(
                  (img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "260px",
                        width: "500px",
                        // marginLeft: "20px",
                      }}
                    >
                      {isEditing ? (
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "7px",
                            right: "-17px",
                            color: "white",
                            backgroundColor: "#6C7270",
                            borderRadius: "50%",
                            height: "17px",
                            width: "18px",
                          }}
                          onClick={() => handleDeleteImage(index, img)}
                        />
                      ) : (
                        ""
                      )}
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "258px",
                          width: isEditing ? "500px" : "480px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  )
                )}
              </div>

              {isEditing ? (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDiscard}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "16px",
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "16px",
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              ) : (
                !disablePrevDate && (
                  <Stack direction="row" mt={7} sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditBtn}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        fontSize: "16px",
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                )
              )}
            </>
          </Popover>
        )}
      </Draggable>
    </ClassWrapper>
  );
};
export default PricingTable;
