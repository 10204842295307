import {
  Box,
  Stack,
  Table,
  TableCell,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  border,
  borderRadius,
  color,
  fontSize,
  height,
  padding,
  textTransform,
  width,
} from "@mui/system";

export const P = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  margin-left: 10px;
`;
export const SubText = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 10px;
`;
export const HeaderCard = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #281e53;
`;
export const LabelNew = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;
export const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  position: "relative",
  boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

export const TableWrapper = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  marginTop: "16px",
  height: "60vh",
  overflowY: "auto",
}));

export const TB = styled(Table)`
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 14px/20px Roboto;
    color: #281e53;
    background: rgba(48, 81, 221, 0.09);
    border: none;
    padding: 3px 0px;
    text-align: center;
    vertical-align: middle;
  }
`;
export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 14px/20px Roboto;
    color: #333333;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 32px;
    padding: 8px 15px;
    text-align: center;
  }
`;

export const MainContainer = styled(Stack)(() => ({
  display: "flex",
  padding: "60px 0px 0px 40px",
  height: "100vh",
  ".leftPanel": {
    backgroundColor: "#163a90",
    width: "30%",
    height: "100%",
    padding: "12px 16px 12px 16px",
    marginLeft: "20px",
  },
  ".leftContent": {
    backgroundColor: "#fff",
    borderRadius: "8px",
    height: "100%",
    padding: "8px 16px",
    overflowY: "auto",
    ".hotelName": {
      fontSize: "22px",
      fontWeight: 500,
      color: "#163A90",
    },
    ".address": {
      fontSize: "16px",
      fontWeight: 400,
    },
    ".dateContainer": {
      border: "1px solid #E7E7E7",
      padding: "10px 5px",
      borderRadius: "8px",
    },
  },
  ".viewContent": {
    width: "70%",
    backgroundColor: "#fff",
    padding: "0px 20px",
    height: "100%",
    ".downloadBtn": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      height: "32px",
      width: "40px",
      color: "rgb(22, 58, 144)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      boxShadow: "rgba(3, 4, 94, 0.2) 0px 4px 4px",
      "&:hover": {
        cursor: "pointer",
      },
    },

    ".eventButton": {
      backgroundColor: "#163A90",
      color: "#fff",
      fontSize: "14px",
      textTransform: "capitalize",
      borderRadius: "30px",
      padding: "8px 16px",
      "&:disabled": {
        cursor: "auto",
        backgroundColor: "#adaaaa",
      },
    },
  },
}));
