import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { matchPath, useHistory } from "react-router-dom";
import { BASE_URL } from "./../constants";
import { useFetch } from "./useFetch";

const initialState = {
  user: null,
  hotels: [],
  hotelSelect: null,
  name: null,
  email: null,
  permission: {},
  token: "loading",
  isLoggedIn: false,
  error: null,
  isLoading: false,
  inValidLogin: false,
  isFirstRender: true,
  currentHotel: null,
};

const simpleReducer = (state, payload) => ({ ...state, ...payload });
const AuthContext = createContext();
const { Provider, Consumer } = AuthContext;

const AuthProvider = ({ children }) => {
  const match = matchPath(window.location.pathname, {
    path: ["/hotel/:hotelId"],
    exact: false,
    strict: false,
  });
  const matchResetPasswordUrl = matchPath(window.location.pathname, {
    path: ["/password_reset"],
    exact: false,
    strict: false,
  });

  const [state, setState] = useReducer(simpleReducer, initialState);

  const { apiFetch, cloneMessage, setCloneMessage } = useFetch(state.token);

  const history = useHistory();

  const authFetch = React.useCallback(
    (...args) =>
      new Promise((res, rej) => {
        if (state.token !== "loading") {
          res(apiFetch(...args));
        }
      }),
    [state.token, apiFetch]
  );

  const updateHotels = () => {
    const token = localStorage.getItem("token");
    const hotels = JSON.parse(localStorage.getItem("hotels"));
    if (token) {
      setState({
        hotels,
      });
    }
  };

  useEffect(() => {
    try {
      const token = localStorage.getItem("token");
      const name = localStorage.getItem("name");
      const email = localStorage.getItem("email");
      const hotels = JSON.parse(localStorage.getItem("hotels"));
      const permission = JSON.parse(localStorage.getItem("permission"));
      const selectedHotel =
        hotels.find((h) => h.id === parseInt(match?.params?.hotelId)) || null;
      if (token) {
        setState({
          token,
          isLoggedIn: true,
          hotels,
          permission,
          hotelSelect: selectedHotel,
          name,
          email,
        });
      }
    } catch (e) {
      setState({
        token: null,
        isLoggedIn: false,
        hotels: [],
        hotelSelect: null,
        permission: {},
        name: null,
        email: null,
      });
    } finally {
      setState({
        isFirstRender: false,
      });
    }
  }, []);

  useEffect(() => {
    if (!!matchResetPasswordUrl) {
      return;
    }
    if (state.isFirstRender) return;
    if (state.token === null) {
      history.push("/");
      return;
    }
    if (
      state.permission[0].name === "listOfOrg" &&
      state.hotelSelect === null &&
      window.location.pathname !== "/hotel"
    ) {
      history.push("/managing_organization");
      return;
    }
    if (
      state.permission[0].name !== "listOfOrg" &&
      state.hotelSelect === null &&
      window.location.pathname !== "/hotel"
    ) {
      history.push("/choose_hotel");
      return;
    }

    if (window.location.pathname === "/hotel" && state.hotelSelect === null) {
      history.push("/hotel");
      return;
    }
  }, [state.token, state.hotelSelect, state.isFirstRender, state.permission]);

  //precium version feature defining globally
  const [version, setVersion] = useState("");
  const [rateShopFeature, setRateShopFeature] = useState(false);
  const hotelId = useMemo(() => window.location.href.split("/")[4], [
    window.location.href,
  ]);

  const getHotelDetails = useCallback(
    async (hotelId) => {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}`,
      });
      const { data, error } = await get();
      setState({ currentHotel: { ...data } });
      if (data) {
        setVersion(data.version);
        setRateShopFeature(data.rateShopEnabled);
      } else {
        console.log(error);
      }
    },
    [hotelId, authFetch]
  );

  useEffect(() => {
    if (
      matchPath(window.location.pathname, {
        path: ["/hotel/:hotelId"],
        params: {},
      })
    ) {
      getHotelDetails(hotelId);
    }
  }, [authFetch, state.token, hotelId]);

  const selectHotel = (hotel, onOrganizationPage = false) => {
    let dashboard = null;
    setState({
      hotelSelect: hotel,
    });
    for (let key in state.permission) {
      if (state.permission.hasOwnProperty(key)) {
        if (state.permission[key].name === "dashboard") {
          dashboard = state.permission[key];
        }
      }
    }
    if (onOrganizationPage) {
      const url = dashboard
        ? "/hotel/" + hotel.id + "/dashboard"
        : "/hotel/" + hotel.id + "/business_on_books";
      history.push(url);
    } else if (hotel && state.hotelSelect) {
      const newUrl = window.location.pathname
        .split("/")
        .map((data, index) => {
          if (index === 2) {
            return hotel.id + "";
          } else {
            return data;
          }
        })
        .join("/");
      history.push(newUrl);
    }
  };

  const login = async ({ username, password }) => {
    try {
      setState({ isLoading: true, inValidLogin: false });
      const { token, name, email, hotels, permission } = await fetch(
        `${BASE_URL}/login`,
        {
          method: "POST",
          body: JSON.stringify({ username, password }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      ).then((res) => res.json());
      if (token) {
        setState({
          isLoggedIn: true,
          hotels,
          permission,
          token,
          error: null,
          name,
          email,
        });
        localStorage.setItem("token", token);
        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("hotels", JSON.stringify(hotels));
        localStorage.setItem("permission", JSON.stringify(permission));
      } else {
        setState({
          inValidLogin: true,
          isLoggedIn: false,
          hotels: [],
          token: null,
          hotelSelect: null,
          permission: {},
          name: null,
          email: null,
        });
      }
    } catch (e) {
      setState({ error: "Error logging in" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("hotels");
    localStorage.removeItem("permission");
    localStorage.removeItem("file");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    setState({
      user: null,
      token: null,
      isLoggedIn: false,
      hotels: [],
      hotelSelect: null,
      permission: {},
      name: null,
      email: null,
    });
  };

  return (
    <Provider
      value={{
        login,
        logout,
        selectHotel,
        authFetch,
        cloneMessage,
        setCloneMessage,
        updateHotels,
        ...state,
        version,
        setVersion,
        rateShopFeature,
        setRateShopFeature,
        getHotelDetails,
      }}
    >
      {children}
    </Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export { AuthProvider, Consumer as AuthConsumer, AuthContext };
